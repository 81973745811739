import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from 'src/config';

// Fetch diagnosis result
export const fetchDiagnosisResult = createAsyncThunk(
    'diagnosis/fetchDiagnosisResult',
    async (diagnosisId, { getState, rejectWithValue }) => {
        const token = getState().auth.token; // Get the token from state
        
        try {
            const response = await axios.get(`${API_URL}/diagnosis/generate/${diagnosisId}`, {
                headers: {
                    Authorization: `Bearer ${token}` // Insert the token in the Authorization header
                }
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
