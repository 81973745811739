import React, { useEffect, useState } from 'react';
import { Container, Box, Typography, TextField, Button, IconButton, Avatar, Modal, Grid, Card, CircularProgress } from '@mui/material';
import { CollectionsBookmarkRounded, Edit as EditIcon, Visibility, VisibilityOff } from '@mui/icons-material';
import Colors from 'src/styles/Colors';
import { FormTextField } from 'src/styles/CustomStyles';
import { FormButton } from 'src/styles/CustomStyles';
import { FormButtonCancel } from 'src/styles/CustomStyles';
import useAxios from 'src/services/apiInterceptor';
import { showSnackbar } from 'src/store/actions/snackbarActions';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProfileData } from 'src/store/actions/profileActions';
import { API_URL } from 'src/config';

const Profil = ({role}) => {
    const api = useAxios();
    const dispatch = useDispatch();

    const { loading, profile, error } = useSelector((state) => state.profile);

    //const [loading, setLoading] = useState(true);
    const [profileData, setProfileData] = useState({});



    const [passwordModalOpen, setPasswordModalOpen] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
   
    const [loadingUpdateDetails, setLoadingUpdateDetails] = useState(false);
    const [loadingUpdateEmail, setLoadingUpdateEmail] = useState(false);
    const [emailErrors, setEmailErrors] = useState({});
    
    
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loadingUpdatePassword, setLoadingUpdatePassword] = useState(false);
    const [passwordErrors, setPasswordErrors] = useState({});
  

   
    
    useEffect(() => {
        if(role == "manager"){
            alert("manager")
        }
        if(role){
            dispatch(fetchProfileData(role));
        }
    }, [dispatch]);

    useEffect(() => {
        if (profile) {
            setProfileData(profile); // Initialize local state with fetched profile data
        }
    }, [profile]);
  
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setProfileData(prevState => ({ ...prevState, [name]: value }));
    };

    const handlePasswordModalOpen = () => setPasswordModalOpen(true);
    const handlePasswordModalClose = () => setPasswordModalOpen(false);
    const handleTogglePasswordVisibility = () => setShowPassword(!showPassword);

    const handleProfilePictureUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;
    
        const formData = new FormData();
        formData.append('file', file);
    
        try {
            const response = await api.post('/users/upload-picture', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response.status === 200) {
                // Update the profile picture in your UI
                dispatch(fetchProfileData())
                // You might want to refetch the profile data or update the state directly
                console.log('Profile picture uploaded successfully');
            } else {
                console.error('Failed to upload profile picture');
            }
        } catch (error) {
            console.error('Error uploading profile picture:', error);
        }
        // Implement the logic to upload the file to `/profile/upload-picture`
    };
    const handleUpdateDetails = async () => {
        try{
            const updatedData = {
                first_name: profileData?.first_name || '',
                last_name: profileData?.last_name || '',
                company: profileData?.company || ''
            };
            setLoadingUpdateDetails(true)
            const response = await api.post('/users/update-detail', updatedData)
            dispatch(showSnackbar(response.data.message, "success"))
            dispatch(fetchProfileData());

        }catch(error){
            console.error('Error updating manager details:', error);
            let errorMsg = error.response?.data?.message || 'Error requesting email update '+ error.message;
            dispatch(showSnackbar(errorMsg, "error"))
        }finally{
            setLoadingUpdateDetails(false)
        }  
    };

    const handleUpdateEmail= async () => {
        try{
            setEmailErrors({});
            const updatedData = {
                email: profileData?.email || '',
            };
            setLoadingUpdateEmail(true)
            const response = await api.post('/users/request-email-update', updatedData)
            dispatch(showSnackbar(response.data.message, "success"))

        }catch(error){
            let msg = error.message;
            if (error?.response?.data) {
                const validationErrors = error.response.data || {}; // Adjust based on your API response structure
                setEmailErrors(validationErrors);
                msg = "Email invalide !"
            } 
            dispatch(showSnackbar(msg, "error"))
            
            //let errorMsg = error.response?.data?.email || "Erreur lors de la mise à jour de l'email : "+ error.message;
        }finally{
            setLoadingUpdateEmail(false)
        }  
    };

    const handleUpdatePassword = async () => {
        setPasswordErrors({});

        if (newPassword !== confirmPassword) {
            dispatch(showSnackbar('Nouveau mot de passe et confirmer le mot de passe sont différents ! Veuillez vérifier.', "error"));
            setPasswordErrors({"new_password": 'Nouveau mot de passe et confirmer le mot de passe ne correspondent pas'})
            return;
        }

        try {
            setLoadingUpdatePassword(true);
            const response = await api.post('/users/update-password', {
                current_password: currentPassword,
                new_password: newPassword,
                confirm_new_password: newPassword,
            });

            if (response.status === 200) {
                dispatch(showSnackbar('Mot de passe mis à jour avec succés', "success"));
                handlePasswordModalClose();
            }
        } catch (error) {
            let msg = error.message;
            if (error?.response?.data) {
                const validationErrors = error.response.data || {}; // Adjust based on your API response structure
                setPasswordErrors(validationErrors);
                msg = "Erreur de mise à jour du mot de passe !"
            } 
            dispatch(showSnackbar(msg, "error"))
        }finally{
            setLoadingUpdatePassword(false);

        }
    }; 

    if (loading) {
        return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', flexDirection: 'column' }}>
            <CircularProgress />
            <Typography variant="h6" sx={{ mt: 2, color: Colors.MEDIUM_GREEN }}>
            Chargement des données du profil...
            </Typography>
        </Box>
        );
    }
    if (error) {
        return <div>Error: {error}</div>;
    }
    return (
        <Container sx={{ mt: 2 }}>
        <Typography variant="h4" sx={{ mb: 3, color: Colors.MEDIUM_GREEN }}>Profil</Typography>

        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'center', justifyContent: 'space-between', mb: 4 }}>
            <Box sx={{ position: 'relative', display: 'inline-block', mb: { xs: 2, sm: 2 } }}>
                <Avatar 
                sx={{ width: 120, height: 120 }} 
                src={profile?.profile_picture ? API_URL+"/users/profile-picture/"+profile.profile_picture : '/user_profile.png'}
                />
                <IconButton
                    component="label"
                    sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    backgroundColor: 'white',
                    borderRadius: '50%',
                    boxShadow: 1,
                    }}
                >
                    <EditIcon />
                    <input
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={handleProfilePictureUpload}
                    />
                </IconButton>
            </Box>

            {
                role && role ==="manager" ?

               ( <Grid container spacing={2} sx={{ maxWidth: { sm: 'calc(100% - 140px)' } }}>
                    <Grid item xs={6} md={3}>
                        <Card sx={{background:Colors.DARK_BLUE, textAlign: 'center', p:2 }}>
                        <Typography variant="body1" sx={{mb:1,fontWeight: "bold",color:Colors.VERY_LIGHT_GREY}}>Cabinets Comptables</Typography>
                        <Typography variant="h4" sx={{color:"#fff"}}>{profileData?.teams?.length || 0}</Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Card sx={{background:Colors.TEXT_BODY, textAlign: 'center', p:2 }}>
                        <Typography variant="body1" sx={{mb:1,fontWeight: "bold", color:Colors.VERY_LIGHT_GREY}}>Experts comptables</Typography>
                        <Typography variant="h4" sx={{color: "#fff"}}>{profileData?.collaborators?.length || 0}</Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Card sx={{background:Colors.MEDIUM_GREEN, textAlign: 'center', p:2 }}>
                        <Typography variant="body1" sx={{mb:1,fontWeight: "bold", color:Colors.VERY_LIGHT_GREY}}>Questionnaires</Typography>
                        <Typography variant="h4" sx={{color:"#fff"}}>{profileData?.surveys?.length || 0} </Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Card sx={{background:Colors.TITLE_2, textAlign: 'center', p:2 }}>
                        <Typography variant="body1" sx={{mb:1,fontWeight: "bold", color:Colors.VERY_LIGHT_GREY}}>Diagnostics</Typography>
                        <Typography variant="h4" sx={{color:"#fff"}}>{profileData?.diagnostics || 0}</Typography>
                        </Card>
                    </Grid>
                </Grid>)
                :
                (
                    <Grid container spacing={2} sx={{ maxWidth: { sm: 'calc(100% - 140px)' } }}>
                   
                    <Grid item xs={6} md={6}>
                        <Card sx={{background:Colors.MEDIUM_GREEN, textAlign: 'center', p:2 }}>
                        <Typography variant="body1" sx={{mb:1,fontWeight: "bold", color:Colors.VERY_LIGHT_GREY}}>Questionnaires</Typography>
                        <Typography variant="h4" sx={{color:"#fff"}}>{profileData?.surveys?.length || 0} </Typography>
                        </Card>
                    </Grid>
                    <Grid item xs={6} md={6}>
                        <Card sx={{background:Colors.TITLE_2, textAlign: 'center', p:2 }}>
                        <Typography variant="body1" sx={{mb:1,fontWeight: "bold", color:Colors.VERY_LIGHT_GREY}}>Diagnostics</Typography>
                        <Typography variant="h4" sx={{color:"#fff"}}>{profileData?.diagnostics || 0}</Typography>
                        </Card>
                    </Grid>
                </Grid>
                )
            }
            
        </Box>

            <Box sx={{ mb: 4 }}>
                <Typography variant="h5" sx={{ mb: 2, fontSize: "18px", color: Colors.TITLE_3 }}>
                    Infos du compte
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <FormTextField 
                            value={profileData?.first_name || ''}  
                            label="Prénom" 
                            name="first_name"
                            InputLabelProps={{ shrink: true }}
                            variant="outlined" 
                            onChange={handleInputChange}
                            fullWidth sx={{ mb: { xs: 2, sm: 2 } }} 
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormTextField 
                            value={profileData?.last_name || ''}  
                            label="Nom"
                            name="last_name"
                            InputLabelProps={{ shrink: true }}
                            variant="outlined" 
                            onChange={handleInputChange}
                            fullWidth sx={{ mb: { xs: 2, sm: 2 } }} 
                        />
                    </Grid>
                </Grid>
                <FormTextField 
                    value={profileData?.company || ''}  
                    label="Entreprise" 
                    name="company"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined" 
                    onChange={handleInputChange}
                    fullWidth sx={{ mb: { xs: 2, sm: 2 } }} 
                />
                <Box sx={{ display: 'flex', justifyContent: 'end', mt: 2 }}>
                    <FormButton size="small" onClick={handleUpdateDetails}>
                        {loadingUpdateDetails ? <CircularProgress size={20} thickness={5} sx={{ color: Colors.GREEN }} /> : null} Mettre à jour
                    </FormButton>
                </Box>
            </Box>

        <Box sx={{ mb: 4 }}>
            <Typography variant="h5" sx={{ mb: 2, fontSize:"18px", color: Colors.TITLE_3}}>Email et mot de passe</Typography>
            <FormTextField 
                value={profileData?.email || ''}  
                label="Email" 
                name="email"
                onChange={handleInputChange}
                InputLabelProps={{ shrink: true }}
                variant="outlined" 
                error={Boolean(emailErrors.email)}
                helperText={emailErrors.email}
                fullWidth sx={{ mb: { xs: 2, sm: 2 } }} 
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
             <FormButton size="small" onClick={handleUpdateEmail}>
                    {loadingUpdateEmail ? <CircularProgress size={20} thickness={5} sx={{ color: Colors.GREEN }} /> : null} Mettre à jour l'email
                </FormButton>
            <FormButtonCancel size="small" variant="outlined" onClick={handlePasswordModalOpen}>
                Changer le mot de passe
            </FormButtonCancel>
            </Box>
        </Box>

        {/* Change Password Modal */}
        <Modal open={passwordModalOpen} onClose={handlePasswordModalClose}>
            <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, p: 4, bgcolor: 'background.paper', boxShadow: 24, borderRadius: 1 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>Changement de mot de passe</Typography>
            <FormTextField
                label="Mot de passe actuel"
                name="current_password"
                variant="outlined"
                error={Boolean(passwordErrors.current_password)}
                helperText={passwordErrors.current_password}
                onChange={(e) => setCurrentPassword(e.target.value)}
                type={showPassword ? 'text' : 'password'}
                fullWidth
                sx={{ mb: 2 }}
                InputProps={{
                endAdornment: (
                    <IconButton onClick={handleTogglePasswordVisibility}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                )
                }}
            />
            <FormTextField
                label="Nouveau mot de passe"
                name="new_password"
                variant="outlined"
                onChange={(e) => setNewPassword(e.target.value)}
                type={showPassword ? 'text' : 'password'}
                fullWidth
                sx={{ mb: 2 }}
                error={Boolean(passwordErrors.new_password)}
                helperText={passwordErrors.new_password}
                InputProps={{
                endAdornment: (
                    <IconButton onClick={handleTogglePasswordVisibility}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                )
                }}
            />
            <FormTextField
                label="Confirmer le mot de passe"
                variant="outlined"
                name="confirm_new_password"
                onChange={(e) => setConfirmPassword(e.target.value)}
                type={showPassword ? 'text' : 'password'}
                fullWidth
                error={Boolean(passwordErrors.confirm_new_password)}
                helperText={passwordErrors.confirm_new_password}
                sx={{ mb: 3 }}
                InputProps={{
                endAdornment: (
                    <IconButton onClick={handleTogglePasswordVisibility}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                )
                }}
            />
                <FormButton size="small" onClick={handleUpdatePassword}>
                    {loadingUpdatePassword ? 
                    <CircularProgress 
                        size={20} thickness={5} 
                        sx={{ color: Colors.GREEN }} 
                    /> : null} 
                    Mettre à jour le mot de passe
                </FormButton>
            </Box>
        </Modal>
        </Container>
    );
};

export default Profil;
