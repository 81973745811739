import React from 'react';
import { Box, Container } from '@mui/material';
import { Outlet } from 'react-router-dom';

const Questionnaire = () => {



  return (
    <Container style={{flex: 1, display: "flex", flexDirection:"column"}}>

        <Outlet/>

     
    </Container>
  );
};

export default Questionnaire;
