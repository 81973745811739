import { FETCH_TEAMS_COLLABORATORS_FAILURE, FETCH_TEAMS_COLLABORATORS_REQUEST, FETCH_TEAMS_COLLABORATORS_SUCCESS, SET_TEAMS_COLLABORATORS_CURRENT_PAGE, SET_TEAMS_COLLABORATORS_FILTERS, SET_TEAMS_COLLABORATORS_PER_PAGE, SET_TEAMS_COLLABORATORS_SORT_BY, SET_TEAMS_COLLABORATORS_SORT_ORDER } from "../actions/teamCollaboratorsActions";

  
  const initialState = {
    collaborators: [],
    totalPages: 1,
    currentPage: 1,
    filters: {},
    sortBy: 'id',
    sortOrder: 'desc',
    perPage: 10,
    loading: false,
    error: null,
  };
  
  const teamsCollaboratorsReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_TEAMS_COLLABORATORS_REQUEST:
        return { ...state, loading: true, error: null };
      case FETCH_TEAMS_COLLABORATORS_SUCCESS:
        return {
          ...state,
          collaborators: action.payload.collaborators,
          totalPages: action.payload.total_pages,
          currentPage: action.payload.current_page,
          loading: false,
        };
      case FETCH_TEAMS_COLLABORATORS_FAILURE:
        return { ...state, loading: false, error: action.payload };
      case SET_TEAMS_COLLABORATORS_FILTERS:
        return { ...state, filters: action.payload };
      case SET_TEAMS_COLLABORATORS_SORT_BY:
        return { ...state, sortBy: action.payload };
      case SET_TEAMS_COLLABORATORS_SORT_ORDER:
        return { ...state, sortOrder: action.payload };
      case SET_TEAMS_COLLABORATORS_PER_PAGE:
        return { ...state, perPage: action.payload };
      case SET_TEAMS_COLLABORATORS_CURRENT_PAGE:
        return { ...state, currentPage: action.payload };
      default:
        return state;
    }
  };
  
  export default teamsCollaboratorsReducer;
  