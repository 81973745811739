// src/actions/statsActions.js

import axios from "axios";
import { API_URL } from "src/config";

export const FETCH_STATS_REQUEST = 'FETCH_STATS_REQUEST';
export const FETCH_STATS_SUCCESS = 'FETCH_STATS_SUCCESS';
export const FETCH_STATS_FAILURE = 'FETCH_STATS_FAILURE';


export const FETCH_TEAMS_STATS_REQUEST = 'FETCH_TEAMS_STATS_REQUEST';
export const FETCH_TEAMS_STATS_SUCCESS = 'FETCH_TEAMS_STATS_SUCCESS';
export const FETCH_TEAMS_STATS_FAILURE = 'FETCH_TEAMS_STATS_FAILURE';


export const FETCH_DASHBOARD_STATS_REQUEST = 'FETCH_DASHBOARD_STATS_REQUEST';
export const FETCH_DASHBOARD_STATS_SUCCESS = 'FETCH_DASHBOARD_STATS_SUCCESS';
export const FETCH_DASHBOARD_STATS_FAILURE = 'FETCH_DASHBOARD_STATS_FAILURE';

export const fetchStatsRequest = () => ({
    type: FETCH_STATS_REQUEST,
});

export const fetchStatsSuccess = (stats) => ({
    type: FETCH_STATS_SUCCESS,
    payload: stats,
});

export const fetchStatsFailure = (error) => ({
    type: FETCH_STATS_FAILURE,
    payload: error,
});

export const fetchStats = () => async (dispatch, getState) => {
    const token = getState().auth.token;
    dispatch(fetchStatsRequest());
    try {
        const response = await axios.get(`${API_URL}/diagnosis/stats`,
            {
                headers:{
                    'Authorization': `Bearer ${token}`
                }
            });
        dispatch(fetchStatsSuccess(response.data));
    } catch (err) {
        dispatch(fetchStatsFailure(err.message));
    }
};






export const fetchTeamsStatsRequest = () => ({
    type: FETCH_TEAMS_STATS_REQUEST,
});

export const fetchTeamsStatsSuccess = (stats) => ({
    type: FETCH_TEAMS_STATS_SUCCESS,
    payload: stats,
});

export const fetchTeamsStatsFailure = (error) => ({
    type: FETCH_TEAMS_STATS_FAILURE,
    payload: error,
});

export const fetchTeamsStats = () => async (dispatch, getState) => {
    const token = getState().auth.token;
    dispatch(fetchTeamsStatsRequest());
    try {
        const response = await axios.get(`${API_URL}/teams/stats`,
            {
                headers:{
                    'Authorization': `Bearer ${token}`
                }
            });
        dispatch(fetchTeamsStatsSuccess(response.data));
    } catch (err) {
        dispatch(fetchTeamsStatsFailure(err.message));
    }
};


export const fetchDashboardStatsRequest = () => ({
    type: FETCH_DASHBOARD_STATS_REQUEST,
});

export const fetchDashboardStatsSuccess = (stats) => ({
    type: FETCH_DASHBOARD_STATS_SUCCESS,
    payload: stats,
});

export const fetchDashboardStatsFailure = (error) => ({
    type: FETCH_DASHBOARD_STATS_FAILURE,
    payload: error,
});

export const fetchDashboardStats = () => async (dispatch, getState) => {
    const token = getState().auth.token;
    dispatch(fetchDashboardStatsRequest());
    try {
        const response = await axios.get(`${API_URL}/dashboard/stats`,
            {
                headers:{
                    'Authorization': `Bearer ${token}`
                }
            });
        dispatch(fetchDashboardStatsSuccess(response.data));
    } catch (err) {
        dispatch(fetchDashboardStatsFailure(err.message));
    }
};

