import axios from "axios";
import { API_URL } from "src/config";

export const SET_USER_SURVEY = 'SET_USER_SURVEY';
export const CLEAR_USER_SURVEYS = 'CLEAR_USER_SURVEYS';
export const REMOVE_USER_SURVEY = 'REMOVE_USER_SURVEY';
export const REMOVE_USER_SURVEY_ERROR = 'REMOVE_USER_SURVEY_ERROR'; // Optional: for error handling

export const ADD_SURVEY_DATA = 'ADD_SURVEY_DATA';
export const REMOVE_SURVEY_DATA = 'REMOVE_SURVEY_DATA';

export const addOrUpdateSurveyData = (surveyData) => (dispatch, getState) => {
    const currentSurveysData = getState().surveysData;
    
    // Check if the survey data already exists
    const surveyIndex = currentSurveysData.findIndex(s => s.id === surveyData.id);
    
    let updatedSurveysData;
    if (surveyIndex !== -1) {
        // Replace the existing survey data
        updatedSurveysData = [...currentSurveysData];
        updatedSurveysData[surveyIndex] = surveyData;
    } else {
        // Add the new survey data
        updatedSurveysData = [...currentSurveysData, surveyData];
    }

    // Dispatch the updated survey data to the store
    dispatch({
        type: ADD_SURVEY_DATA,
        payload: updatedSurveysData,
    });

    // Persist the updated data to localStorage
    localStorage.setItem('surveysData', JSON.stringify(updatedSurveysData));
};

export const removeSurveyData = (surveyId) => ({
    type: REMOVE_SURVEY_DATA,
    payload: surveyId,
});
// Check if survey already exists before adding
export const addOrUpdateUserSurvey = (survey) => (dispatch, getState) => {
    const currentUserSurveys = getState().currentUserSurveys;
    console.log("From addOrUpdateUserSurvey Action : currentUserSurveys at start", currentUserSurveys);

    // Convert payload to array if it's a single object
    const surveyArray = Array.isArray(survey) ? survey : [survey];
    
    // Check if the survey already exists in state
    const updatedSurveys = surveyArray.reduce((acc, newSurvey) => {
        const surveyIndex = acc.findIndex(s => s.survey_id === newSurvey.survey_id);
        console.log("From addOrUpdateUserSurvey Action : surveyIndex", surveyIndex);

        if (surveyIndex !== -1) {
            // Update existing survey
            acc[surveyIndex] = newSurvey;
        } else {
            // Add new survey
            acc.push(newSurvey);
        }
        return acc;
    }, [...currentUserSurveys]);

    console.log("From addOrUpdateUserSurvey Action : currentUserSurveys at end (updatedSurveys)", updatedSurveys);

    // Dispatch the updated user survey data to the store
    dispatch({
        type: SET_USER_SURVEY,
        payload: updatedSurveys,
    });

    // Persist the updated data to localStorage
    localStorage.setItem('currentUserSurveys', JSON.stringify(updatedSurveys));
}
// Action to remove a user survey
export const removeUserSurvey = (surveyId) => async (dispatch, getState) => {
    try {
       const token = getState().auth.token;
    // Perform the API call to remove the entry from the database
    await axios.delete(`${API_URL}/surveys/delete_current_user_survey/${surveyId}`, {
      headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
      },
    });

    // Get the current state of surveys
    const currentUserSurveys = getState().currentUserSurveys;
    
    // Filter out the survey to be removed
    const updatedSurveys = currentUserSurveys.filter(s => s.survey_id !== surveyId);

    // Dispatch action to update Redux state
    dispatch({
      type: REMOVE_USER_SURVEY,
      payload: surveyId,
    });

    // Persist to localStorage
    localStorage.setItem('currentUserSurveys', JSON.stringify(updatedSurveys));
  } catch (error) {
    console.error('Error removing user survey:', error);
    dispatch({
      type: REMOVE_USER_SURVEY_ERROR,
      payload: error.message,
    });
  }
};

export const clearUserSurveys = () => (dispatch) => {
  dispatch({
    type: CLEAR_USER_SURVEYS,
  });

  // Remove from localStorage
  localStorage.removeItem('currentUserSurveys');
};