import axios from "axios";
import { API_URL } from "src/config";

export const SET_USER = 'SET_USER';
export const SET_TOKEN = 'SET_TOKEN';

export const setUser = (user) => ({
    type: SET_USER,
    payload: user,
});

// Action to set the token
export const setToken = (token) => {
    return async (dispatch, getState) => {
        const userToken = getState().auth.token;

        if (token === null) {
            // Call the logout endpoint if token is being cleared
            try {
                const response =  await axios.post(API_URL+'/auth/logout', {
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${userToken}`,
                    }
                  });
                  localStorage.removeItem('accessToken');
                  localStorage.removeItem('user');
                  console.log(response?.data) 
            } catch (error) {
                console.error('Error logging out:', error);
            }
        }
        
        // Dispatch the action to update the token in state
        dispatch({
            type: SET_TOKEN,
            payload: token,
        });
    };
};
