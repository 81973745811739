import React, { useState } from 'react';
import styled from '@emotion/styled';
import { TextField, Typography, Snackbar, Alert } from '@mui/material';
import Colors from '../../styles/Colors';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Form = styled.form`
  width: 85%;
  padding: 2rem;
  background: #fff;
  min-height: 340px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const StyledButton = styled.button`
  padding: 0.75rem;
  border: none;
  border-radius: 12px;
  background-color: ${Colors.DARK_BLUE};
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${Colors.DARK_GREY};
  }
`;

const CompteCreation = styled.p`
  font-size: 14px;
  margin-bottom: 15px;
  margin-top: 3px;
`;

const ForgetPassword = () => {
  const [email, setEmail] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/auth/forgot-password`, { email });
      setSnackbarMessage('Password reset email sent!');
      setSnackbarSeverity('success');
    } catch (error) {
      console.log(error)
      let errorMessage = error;
      if (error.response.data.message) errorMessage = error.response.data.message;
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity('error');
    }
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Typography variant="h5" style={{ fontWeight: 'bold', marginBottom: 0 }} component="h1" gutterBottom>
        Réinitialiser le mot de passe
      </Typography>
      
      <div style={{ width: "100%" }}>
        <TextField
          label="Adresse Email"
          variant="outlined"
          fullWidth
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          InputProps={{
            style: { borderRadius: '10px' }
          }}
        />
        <StyledButton type="submit">Réinitialiser</StyledButton>
      </div>
      
      <CompteCreation>
        Revenir à la page de connexion?{' '}
        <button
          style={{ color: Colors.GREEN, background: 'transparent', border: 'none', cursor: 'pointer' }}
          onClick={() => navigate(-1)}
        >
          Connexion
        </button>
      </CompteCreation>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Form>
  );
};

export default ForgetPassword;
