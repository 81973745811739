import axios from "axios";
import { API_URL } from "src/config";

export const FETCH_PROFILE_REQUEST = 'FETCH_PROFILE_REQUEST';
export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS';
export const FETCH_PROFILE_FAILURE = 'FETCH_PROFILE_FAILURE';

export const fetchProfileRequest = () => ({
    type: FETCH_PROFILE_REQUEST,
});

export const fetchProfileSuccess = (profile) => ({
    type: FETCH_PROFILE_SUCCESS,
    payload: profile,
});

export const fetchProfileFailure = (error) => ({
    type: FETCH_PROFILE_FAILURE,
    payload: error,
});

export const fetchProfileData = (role) => {
    console.log("from action", role)
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        const stateRole = getState().auth.user?.role;
        dispatch(fetchProfileRequest());

        try {
            const response = await axios.get(`${API_URL}/users/${stateRole || "manager"}/profile-data`,{
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                  }
            }) ;
            const data = response.data;
            console.log("hello from thunk => ", data)
            dispatch(fetchProfileSuccess(data));
        } catch (error) {
            dispatch(fetchProfileFailure(error.message));
        }
    };
};