import React from 'react';
import { Container } from '@mui/material';

const Bilan = () => {
  return (
    <Container sx={{ mt: 2 }}>
      <h1>Bilan</h1>
      <p>View and manage reports and summaries here.</p>
      {/* Add content for viewing and managing reports */}
    </Container>
  );
};

export default Bilan;
