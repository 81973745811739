import { FETCH_DASHBOARD_STATS_FAILURE, FETCH_DASHBOARD_STATS_REQUEST, FETCH_DASHBOARD_STATS_SUCCESS, FETCH_STATS_FAILURE, FETCH_STATS_REQUEST, FETCH_STATS_SUCCESS, FETCH_TEAMS_STATS_FAILURE, FETCH_TEAMS_STATS_REQUEST, FETCH_TEAMS_STATS_SUCCESS } from "../actions/statsActions";


const initialState = {
    diagnosisCount: 0,
    personalDiagnosisCount: 0,
    teamsWithUsersSurveyCount: 0,
    totalCollaborators:0,
    teamsUnderReview:0,
    collaboratorsUnderReview:0,
    totalTeams:0,


    totalDiagsInprogress: 0,
    totalDiagsFinished: 0,
    totalTeamsDiags: 0,
    totalTeamsDiagsList: [],
    totalManagedTeams:0,
    totalManagedCollaborators: 0,

    
    loading: false,
    error: null,
};

const statsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_STATS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case FETCH_STATS_SUCCESS:
            return {
                ...state,
                diagnosisCount: action.payload.managed_users_surveys_diagnosis_count,
                personalDiagnosisCount: action.payload.personal_users_surveys_diagnosis_count,
                teamsWithUsersSurveyCount: action.payload.teams_with_users_passing_survey_count,
                loading: false,
                error: null,
            };
        case FETCH_STATS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };


        case FETCH_TEAMS_STATS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case FETCH_TEAMS_STATS_SUCCESS:
            return {
                ...state,
                totalTeams: action.payload.total_teams,
                teamsUnderReview: action.payload.teams_under_review,
                totalCollaborators: action.payload.total_collaborators,
                collaboratorsUnderReview: action.payload.collaborators_under_review,
                loading: false,
                error: null,
            };
        case FETCH_TEAMS_STATS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
    
        
        case FETCH_DASHBOARD_STATS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case FETCH_DASHBOARD_STATS_SUCCESS:
            return {
                ...state,
                totalDiagsInprogress: action.payload.total_users_surveys_in_progress_count,
                totalDiagsFinished: action.payload.total_users_surveys_finished_count,
                totalTeamsDiags: action.payload.total_teams_under_diagnosis_count,
                totalTeamsDiagsList: action.payload.total_teams_under_diagnosis_list,
                totalManagedTeams: action.payload.total_teams_managed_count,
                totalManagedCollaborators: action.payload.total_collaborators_managed_count,
                loading: false,
                error: null,
            };
        case FETCH_DASHBOARD_STATS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default statsReducer;