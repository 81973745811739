import axios from 'axios';
import { API_URL } from 'src/config';

export const FETCH_TEAMS_COLLABORATORS_REQUEST = 'FETCH_TEAMS_COLLABORATORS_REQUEST';
export const FETCH_TEAMS_COLLABORATORS_SUCCESS = 'FETCH_TEAMS_COLLABORATORS_SUCCESS';
export const FETCH_TEAMS_COLLABORATORS_FAILURE = 'FETCH_TEAMS_COLLABORATORS_FAILURE';
export const SET_TEAMS_COLLABORATORS_FILTERS = 'SET_TEAMS_COLLABORATORS_FILTERS';
export const SET_TEAMS_COLLABORATORS_SORT_BY = 'SET_TEAMS_COLLABORATORS_SORT_BY';
export const SET_TEAMS_COLLABORATORS_SORT_ORDER = 'SET_TEAMS_COLLABORATORS_SORT_ORDER';
export const SET_TEAMS_COLLABORATORS_PER_PAGE = 'SET_TEAMS_COLLABORATORS_PER_PAGE';
export const SET_TEAMS_COLLABORATORS_CURRENT_PAGE = 'SET_TEAMS_COLLABORATORS_CURRENT_PAGE';

export const fetchTeamsCollaborators = (page = 1) => async (dispatch, getState) => {
  const { filters, sortBy, sortOrder, perPage } = getState().collaborators;
  const token = getState().auth.token;

  dispatch({ type: FETCH_TEAMS_COLLABORATORS_REQUEST });
  
  try {
    const response = await axios.get(`${API_URL}/teams/managed_users/paginate`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      params: {
        page,
        per_page: perPage,
        filters: new URLSearchParams(filters).toString(),
        sort_by: sortBy,
        sort_order: sortOrder
      }
    });
    
    dispatch({
      type: FETCH_TEAMS_COLLABORATORS_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    dispatch({
      type: FETCH_TEAMS_COLLABORATORS_FAILURE,
      payload: error.message
    });
  }
};

export const setFilters = (filters) => ({
  type: SET_TEAMS_COLLABORATORS_FILTERS,
  payload: filters
});

export const setSortBy = (sortBy) => ({
  type: SET_TEAMS_COLLABORATORS_SORT_BY,
  payload: sortBy
});

export const setSortOrder = (sortOrder) => ({
  type: SET_TEAMS_COLLABORATORS_SORT_ORDER,
  payload: sortOrder
});

export const setPerPage = (perPage) => ({
  type: SET_TEAMS_COLLABORATORS_PER_PAGE,
  payload: perPage
});

export const setCurrentPage = (currentPage) => ({
  type: SET_TEAMS_COLLABORATORS_CURRENT_PAGE,
  payload: currentPage
});
