import React, { useState, useEffect, useRef } from 'react';
import { Box, DialogTitle, DialogContent, DialogActions, Typography, IconButton, CircularProgress, MenuItem, Select, InputLabel, FormControl, Chip, Dialog, Checkbox, ListItemText } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { FormButton, FormButtonCancel } from 'src/styles/CustomStyles';
import Colors from 'src/styles/Colors';
import useAxios from 'src/services/apiInterceptor';
import { showSnackbar } from 'src/store/actions/snackbarActions';
import { useDispatch } from 'react-redux';
import { InfoOutlined, RefreshOutlined } from '@mui/icons-material';

/**
 * AssignTeams component to manage team assignments for surveys.
 *
 * @param {Object} props - Component properties.
 * @param {boolean} props.open - Boolean indicating if the dialog is open.
 * @param {function} props.onClose - Function to handle closing the dialog.
 * @param {function} props.onSubmit - Function to handle submission of assigned teams.
 * @param {number} props.survey - ID of the currently selected survey.
 * @param {Array} props.surveys - List of all surveys for selection.
 * @param {Array} props.teams - List of all available teams.
 * @param {Object} props.errors - Object containing validation errors.
 * @param {boolean} props.loading - Boolean indicating if the submission is in progress.
 * @returns {JSX.Element} - Rendered component.
 */
const AssignTeams = ({ open, onClose, onSubmit, survey, surveys, teams: initialTeams }) => {
  const api = useAxios(); // Custom hook for making API requests
  const dispatch = useDispatch(); // Redux dispatch function for showing snackbars
  const [selectedSurvey, setSelectedSurvey] = useState(survey || ''); // State for the selected survey
  const [selectedTeam, setSelectedTeam] = useState([]); // State for the selected teams
  const [teams, setTeams] = useState(initialTeams); // State for all teams
  const prevSurveyRef = useRef(selectedSurvey); // Ref to keep track of the previous survey
    const [loading, setLoading] = useState(false);
  // Update selected survey when the `survey` prop changes
  useEffect(() => {
    if (survey && survey !== selectedSurvey) {
      setSelectedSurvey(survey);
    }
  }, [survey, selectedSurvey]);

  // Fetch teams when the selected survey changes
  useEffect(() => {
    if (prevSurveyRef.current !== selectedSurvey) {
      const fetchTeams = async () => {
        try {
          console.log(`Fetching teams for survey: ${selectedSurvey}`);
          const response = await api.get(`/surveys/teams/${selectedSurvey}`);
          console.log(`Fetched teams: ${JSON.stringify(response.data)}`);
          setTeams(initialTeams); // Ensure all teams are shown
          setSelectedTeam(response.data.map(team => team.id)); // Mark fetched teams as selected
        } catch (error) {
          console.error('Error fetching teams:', error);
          dispatch(showSnackbar('Error fetching teams: ' + error.message, 'error'));
        }
      };

      fetchTeams();
      prevSurveyRef.current = selectedSurvey; // Update previous survey ref
    }
  }, [selectedSurvey, api, dispatch, initialTeams]);

  // Handle survey selection change
  const handleSurveyChange = (event) => {
    setSelectedSurvey(event.target.value);
  };

  // Handle team selection change
  const handleTeamChange = (event) => {
    setSelectedTeam(event.target.value); // MUI handles multiple values as an array
  };

  // Handle assigning teams to the survey
  const handleAssignTeams = async () => {
    try {
      setLoading(true);
      await api.post(`/surveys/teams/assign/${selectedSurvey}`, {
        teams: selectedTeam
      });
      dispatch(showSnackbar('Cabinets affectées avec succès', 'success'));
      onSubmit(); // Call onSubmit callback after successful assignment
    } catch (error) {
      dispatch(showSnackbar("Erreur lors de l'affectation des cabinets comptables: " + error.message, 'error'));
    } finally {
      setLoading(false);

    }
  };


  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '600px', maxWidth: '80%' } }}
      open={open} onClose={onClose}>
      <DialogTitle>
        <Box sx={{ position: 'relative', py: 1, display: 'flex', alignItems: 'center' }}>
          <Groups2OutlinedIcon style={{ width: 24, height: 24, marginRight: 8 }} />
          <Typography variant="h6" color={Colors.DARK_GREEN} fontWeight={700}>
            {survey ? `Affecter des cabinets comptables aux questionnaire ${survey}` : 'Affecter des cabinets comptables'}
          </Typography>
          <IconButton onClick={onClose} sx={{ position: 'absolute', top: 8, right: 8 }}>
            <CloseOutlinedIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {!survey ? (
          <>
            
            
            <FormControl fullWidth margin="dense">
              <InputLabel>Questionnaire</InputLabel>
              <Select
                value={selectedSurvey}
                onChange={handleSurveyChange}
                label="Questionnaire"
              >
                {surveys.map((s) => (
                  <MenuItem key={s.id} value={s.id}>
                    {s.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box sx={{my:1, display:"flex", alignItems:"center"}}>
                { selectedSurvey ? 
            
                    <Typography sx={{display:"flex", alignItems:"center"}} variant='body2' color={Colors.GREEN}>
                         <CheckCircleOutlinedIcon height={"18px"} width={"18px"} sx={{mr:2}}/> 
                         Questionnaire sélectionné 
                    </Typography>
                : 
                    <Typography sx={{display:"flex", alignItems:"center"}} variant='body2' color={Colors.TITLE_2}>
                         <InfoOutlined height={"18px"} width={"18px"} sx={{mr:2}}/>
                         Veuillez choisir un questionnaire 
                    </Typography> 
                }
            </Box>
            <FormControl fullWidth margin="dense">
              <InputLabel>Cabinets</InputLabel>
              <Select
                multiple
                label="Cabinets"
                value={selectedTeam}
                onChange={handleTeamChange}
                renderValue={(selected) => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    {selected.map((teamId) => {
                      const team = teams.find(t => t.id === teamId);
                      return (
                        <Chip
                          key={teamId}
                          label={team.name}
                          icon={<CheckCircleOutlinedIcon style={{color:"#fff"}}/>}
                          sx={{ backgroundColor: Colors.GREEN, color: 'white' }}
                        />
                      );
                    })}
                  </Box>
                )}
                
              >
                {teams.map((team) => (
                   <MenuItem
                        key={team.id}
                        value={team.id}
                        style={{
                            backgroundColor: selectedTeam.includes(team.id) ? Colors.GREEN : 'inherit', // Custom background for selected items
                            color: selectedTeam.includes(team.id) ? '#fff' : 'inherit' // Custom text color for selected items
                        }}
                    >
                            <ListItemText primary={team.name} />
                    </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        ) : (
                <FormControl fullWidth margin="dense">
                <InputLabel>Cabinets</InputLabel>
                <Select
                    multiple
                    label="Cabinets"
                    value={selectedTeam}
                    onChange={handleTeamChange}
                    renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {selected.map((teamId) => {
                        const team = teams.find(t => t.id === teamId);
                        return (
                            <Chip
                            key={teamId}
                            label={team.name}
                            icon={<CheckCircleOutlinedIcon style={{color:"#fff"}}/>}
                            
                            sx={{ backgroundColor: Colors.GREEN, color: 'white' }}
                            />
                        );
                        })}
                    </Box>
                    )}
                    
                >
                    {teams.map((team) => (
                    <MenuItem
                            key={team.id}
                            value={team.id}
                            style={{
                                backgroundColor: selectedTeam.includes(team.id) ? Colors.GREEN : 'inherit', // Custom background for selected items
                                color: selectedTeam.includes(team.id) ? '#fff' : 'inherit' // Custom text color for selected items
                            }}
                        >
                                <ListItemText primary={team.name} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        )}
      </DialogContent>
      <DialogActions
        sx={{ px: 2, py: 1 }}
        style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center' }}
      >
          <FormButtonCancel variant="outlined" onClick={onClose}>Annuler</FormButtonCancel>
          <FormButton onClick={handleAssignTeams} disabled={loading}>
            {loading ? <CircularProgress size={20} thickness={5} sx={{ color: Colors.GREEN }} /> : "Confirmer l'affectation"}
          </FormButton>
      </DialogActions>
    </Dialog>
  );
};

export default AssignTeams;
