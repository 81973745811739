// services/axiosConfig.js
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { API_URL } from 'src/config';
import { setUser } from 'src/store/actions/authActions';
import { setToken } from 'src/store/actions/authActions';

const useAxios = () => {
    const token = useSelector((state) => state.auth.token);
    const dispatch = useDispatch();
    const api = axios.create({
        baseURL: API_URL, // Update with your API URL
    });

    api.interceptors.request.use(
        (config) => {
        if (token) {
            console.log(token)
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
        },
        (error) => {
        return Promise.reject(error);
        }
    );
    api.interceptors.response.use(
        response => response,
        async (error) => {
            const { response } = error;
            if (response && response.status === 401) { // Handle token expiration
                // Handle token refresh logic here if applicable
                // For example, redirect to login or refresh token
                dispatch(setToken(null));
                dispatch(setUser(null));
                // Optionally redirect to login page
                //window.location.href = '/auth'; // Adjust the redirect as needed
            }
            return Promise.reject(error);
        }
    );
  return api;
};

export default useAxios;
