import { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import useAxios from 'src/services/apiInterceptor';
import { useDispatch } from 'react-redux';
import { showSnackbar } from 'src/store/actions/snackbarActions';
import { FormButtonCancel, FormButton } from 'src/styles/CustomStyles';
import { CloseOutlined, PasswordOutlined, RefreshOutlined } from '@mui/icons-material';
import Colors from 'src/styles/Colors';

const RegeneratePassword = ({ collaborator, onClose, onPasswordRegenerated }) => {
    const api = useAxios();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);

    const handleRegeneratePassword = async () => {
        setLoading(true);
        try {
            await api.put(`/teams/collaborators/${collaborator.id}/regenerate-password`);
            dispatch(showSnackbar(`Le mot de passe de l'Expert Comptable a été régénéré avec succès`, 'success'));
            onPasswordRegenerated();
        } catch (error) {
            dispatch(showSnackbar("Échec de la régénération du mot de passe", 'error'));
        } finally {
            setLoading(false);
            onClose();
        }
    };

    return (
        <Dialog 
            open 
            onClose={onClose}
            maxWidth="md"
            fullWidth
            PaperProps={{
                sx: { width: '500px', minHeight: '250px', maxHeight: '90vh' },
            }}
        >
            <DialogTitle sx={{position:"relative", display:"flex", alignItems:"center", justifyContent:"start", gap:"5px"}}>
                <PasswordOutlined sx={{color: Colors.MEDIUM_GREEN}}/>
                Régénérer le mot de passe de l'Expert Comptable <span style={{color: Colors.LIGHT_BLUE}}> {collaborator?.first_name} {collaborator?.last_name}</span> 
                <IconButton
                    onClick={onClose}
                    sx={{ position: 'absolute', top: 8, right: 8 }}
                >
                    <CloseOutlined />
                </IconButton>    
            </DialogTitle>
            <DialogContent>
                <Typography variant="body1" sx={{mt:2}}>
                    Êtes-vous sûr de vouloir régénérer le mot de passe de l'Expert Comptable <span style={{color: Colors.LIGHT_BLUE}}> {collaborator?.first_name} {collaborator?.last_name}</span>?
                </Typography>
                <Typography variant="body1" sx={{mt:2}}>
                    Un nouveau mot de passe sera envoyé à son adresse e-mail.
                </Typography>
            </DialogContent>
            <DialogActions>
                <FormButtonCancel onClick={onClose} color="secondary">Annuler</FormButtonCancel>
                <FormButton onClick={handleRegeneratePassword} color="primary" disabled={loading}>
                    {loading ? 'Régénération...' : 'Confirmer'}
                </FormButton>
            </DialogActions>
        </Dialog>
    );
};

export default RegeneratePassword;
