import React from 'react';
import Layout from '../_layout/Layout';
import { Container } from '@mui/material';

const Manager = () => {
  return (
    <Layout role="manager">
      <Container sx={{ mt: 2 }}>
        <h1>Manager Dashboard</h1>
        <p>Welcome, Manager! This is the manager dashboard.</p>
        {/* Add manager-specific content here */}
      </Container>
    </Layout>
  );
};

export default Manager;
