/* eslint-disable no-unused-expressions */
import { BarChartOutlined, ChevronLeftOutlined, CloseOutlined } from "@mui/icons-material";
import {  Box, Card, CardContent, Chip, CircularProgress, Dialog,  DialogTitle, Divider, Grid, IconButton, List, ListItem, ListItemText, Typography } from "@mui/material";
import {  useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDiagnosisResult } from "src/store/actions/resultDiagnosisActions";
import Colors from "src/styles/Colors";
import { FormButtonCancel } from "src/styles/CustomStyles";
import GlobalRadarChartComponent from "../components/GlobalRadarChart";
import { FormButton } from "src/styles/CustomStyles";
import { ScrollingDialogContent } from "src/styles/CustomStyles";
import {
    useGaugeState,
    Gauge,
    gaugeClasses,
  } from '@mui/x-charts/Gauge';
import { ScrollingBox } from "src/styles/CustomStyles";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useNavigate } from "react-router-dom";

const ResultsModal = ({ open, onClose, onSubmit, diagnosis, loading, isDialog}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { diagnosisResult, loading: resultLoading, error } = useSelector((state) => state.diagnosisResult);
    const { user } = useSelector((state) => state.auth);
    const [localResult, setLocalResult]= useState(null);
    const [contentLoaded, setContentLoaded] =useState(false)

    //const [expanded, setExpanded] = useState(true);
    // Create a ref for each section
    const sectionRefs = useRef([]);

    // Scroll to the section when clicked
    const handleScrollToSection = (index) => {
    sectionRefs.current[index].scrollIntoView({ behavior: 'smooth' });
    };

/*     const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    }; */

    useEffect(() => {
        if (diagnosis) {
            console.log("From Diagnosis Results Fetch")
            console.log(diagnosis)
            dispatch(fetchDiagnosisResult(diagnosis.id));
            
        }
    }, [diagnosis, dispatch]);
  
    const pdfRef =useRef();
    const [loadingPdf,setloadingPdf] = useState(false);

    const handleDownloadPdf = async () => {
        const input = pdfRef.current;
        setloadingPdf(true);
    
        // Create a clone of the content
        const clonedNode = input.cloneNode(true);
        clonedNode.style.height = 'auto';
        clonedNode.style.maxHeight = 'none';
        clonedNode.style.overflow = 'visible';
        document.body.appendChild(clonedNode);
    
        const pdf = new jsPDF('p', 'mm', 'a4');
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();
    
        // Define margins in mm
        const topMargin = 10; // Top margin in px
        const bottomMargin = 20; // Bottom margin in px
        const pxToMm = 0.264583; // 1 px = 0.264583 mm
        const topMarginMm = topMargin * pxToMm;
        const bottomMarginMm = bottomMargin * pxToMm;
        const contentHeight = pdfHeight - topMarginMm - bottomMarginMm;
    
        // Capture the overview content
        const overview = clonedNode.querySelector('.overview');
        if (overview) {
            const canvas = await html2canvas(overview, {
                scrollX: -window.scrollX,
                scrollY: -window.scrollY,
                useCORS: true,
                scale: 1.8,
            });
    
            const imgData = canvas.toDataURL('image/jpeg', 0.9);
            const imgWidth = pdfWidth;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
    
            pdf.addImage(imgData, 'JPEG', 0, topMarginMm, imgWidth, imgHeight);
        }
    
        // Capture section-brief content
        const sections = clonedNode.querySelectorAll('.section-brief');
        for (let i = 0; i < sections.length; i += 3) {
            //if (i > 0) {
                pdf.addPage();
            //}
    
            for (let j = i; j < i + 3 && j < sections.length; j++) {
                const section = sections[j];
    
                // Capture each section as a separate image
                const canvas = await html2canvas(section, {
                    scrollX: -window.scrollX,
                    scrollY: -window.scrollY,
                    useCORS: true,
                    scale: 1.8,
                });
    
                const imgData = canvas.toDataURL('image/jpeg', 0.9);
                const imgWidth = pdfWidth;
                const imgHeight = (canvas.height * imgWidth) / canvas.width;
    
                // Calculate vertical offset for placing images on the same page
                const position = topMarginMm + j % 3 * imgHeight;
                pdf.addImage(imgData, 'JPEG', 0, position, imgWidth, imgHeight);
            }
        }
    
        await pdf.save('rapport_de_synthese_diag-experts_' + new Date().getMilliseconds() + '.pdf');
    
        // Clean up the cloned node
        document.body.removeChild(clonedNode);
    
        setloadingPdf(false);
    };
    
    
    
    
    
    
    
    // Helper function to load an image
    const loadImage = (url) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = reject;
            img.src = url;
        });
    };
    

    useEffect(() => {
        if (diagnosisResult) {
            console.log("From Diagnosis Results handle")
            console.log(diagnosisResult)
            let { totalScore, totalQuestions } = diagnosisResult.section_scores.reduce(
                (acc, section) => {
                  if (section.isAnswered) {
                    acc.totalScore += section.score;
                    acc.totalQuestions += section.total_questions;
                  }
                  return acc;
                },
                { totalScore: 0, totalQuestions: 0 }
              );
              
              let moyenneGenerale = totalQuestions > 0 ? totalScore / totalQuestions : 0;

              let recommandation = "";
              if (moyenneGenerale >= -1 && moyenneGenerale <= -0.5) {
                  recommandation = "Votre cabinet se trouve actuellement dans une phase critique nécessitant une attention immédiate. Les processus fondamentaux, de la gestion interne à l'approche client, manquent de structure et d'efficacité. Cette situation impacte négativement votre performance globale et votre capacité à vous démarquer sur le marché. Il est urgent de mettre en place des stratégies d'amélioration dans tous les domaines pour assurer la pérennité et la croissance de votre cabinet.";
              } else if (moyenneGenerale > -0.5 && moyenneGenerale <= 0.5) {
                  recommandation = "Votre cabinet se situe dans une phase de transition. Vous avez mis en place des bases dans plusieurs domaines clés, mais il reste un potentiel d'amélioration significatif. Vos efforts actuels créent une fondation, mais manquent encore de cohérence et d'efficacité optimale. En concentrant vos efforts sur le renforcement et l'optimisation de vos processus et stratégies, vous pouvez considérablement améliorer la performance et la compétitivité de votre cabinet.";
              } else if (moyenneGenerale > 0.5 && moyenneGenerale <= 1.5) {
                  recommandation = "Votre cabinet démontre une bonne maîtrise globale de ses opérations et stratégies. Vous avez réussi à mettre en place des processus efficaces dans la plupart des domaines clés, ce qui se traduit par une performance solide et une bonne position sur le marché. Votre défi maintenant est de passer de \"bon\" à \"excellent\" en affinant vos approches, en innovant continuellement et en visant l'excellence dans chaque aspect de votre activité pour vous démarquer davantage de la concurrence.";
              } else if (moyenneGenerale > 1.5 && moyenneGenerale <= 2) {
                  recommandation = "Votre cabinet se distingue comme un leader dans le secteur de l'expertise comptable. Vous excellez dans pratiquement tous les aspects de votre activité, de la gestion interne à l'expérience client, en passant par l'innovation et la performance financière. Votre approche sophistiquée et votre excellence opérationnelle vous positionnent comme un modèle dans l'industrie. Votre défi est maintenant de maintenir cette position de leader, de continuer à innover et de définir de nouvelles normes d'excellence dans le secteur.";
              }

            let result = { ...diagnosisResult, moyenne: Math.round(moyenneGenerale * 100) / 100, recommandation: recommandation };
 
            console.log("localvar result",result)
            setLocalResult(result)
            setContentLoaded(true)
            /* setExpanded(null) */


        }
    }, [diagnosisResult]);


    const getColor = (score, isAnswered) =>{
        if(isAnswered){
            if (score >= -1 && score < -0.5) {
                return Colors.PAS_DU_TOUT; // Low score
            } else if (score >= -0.5 && score < 0) {
                return Colors.PEU;// Medium score
            } else if (score >= 0 && score < 0.5) {
                return Colors.PEU_2;// Medium score
            } else if (score>= 0.5 && score < 1) {
                return Colors.MODEREMENT; // Average score
            } else if (score >= 1 && score < 1.5) {
                return Colors.MODEREMENT_2;// Medium score
            } else if (score>= 1.5 && score <= 2.0) {
                return Colors.TOTALEMENT; // Good score
            } else {
                return Colors.TITLE_2; // Excellent score
            }
        }else{
            return Colors.TITLE_2;
        }
        
    }
    if(open && !contentLoaded) {
        return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', flexDirection: 'column' }}>
            <CircularProgress sx={{color: Colors.MEDIUM_GREEN}}/>
            <Typography variant="h6" sx={{ mt: 2, color: Colors.MEDIUM_GREEN }}>
            Chargement des données, veuillez patienter un moment ...
            </Typography>
        </Box>
        );
    }
    return (
      <Dialog
        sx={{
                bgColor: Colors.VERY_LIGHT_GREY,
            '& .MuiDialog-paper':
             { 
                maxWidth:isDialog ? '95vw !important' :'100vw !important',
                maxHeight:isDialog ? '97vh !important' :'100vh !important',
                margin: isDialog ? '32px' :'0px !important',
                width: '100vw',
                height:'100vh' } }}
        open={open} onClose={onClose}>
        <DialogTitle sx={{position: 'relative', backgroundColor: Colors.VERY_LIGHT_GREY,}}>
            <Box 
                sx={{ 
                    display: 'flex', 
                    justifyContent:"space-between",
                    alignItems: 'center' 
                }}
            >
                <Box sx={{display: 'flex', alignItems: 'center' }}>
                    <BarChartOutlined style={{ width: 24, height: 24, marginRight: 8, color: Colors.GREEN }} />
                    <Box>
                        <Box sx={{gap:"3px", display:"flex", justifyContent: "start", alignItems:"center", flexWrap:"wrap"}}>
                            <Typography variant="h5" color={Colors.TITLE_1} fontWeight={700}>
                                Synthèse et résultats
                            </Typography>
                            <Typography variant="h5" color={Colors.LIGHT_BLUE} fontWeight={700}>
                            {localResult ? ` ${localResult.survey_name}` : null}
                            </Typography>
                        </Box>
                       
                        <Typography variant="h5" color={Colors.LIGHT_BLUE} fontWeight={600}>
                         {localResult ? ` ${localResult.user_first_name} ${localResult.user_last_name} | ${localResult.user_company || null}` : null}
                        
                        </Typography>
                    </Box>
                </Box>
            
                <FormButton 
                
                    size="small" 
                    sx={{mr:3, minWidth:"100px"}} 
                    onClick={handleDownloadPdf}
                >
                    {loadingPdf ? <CircularProgress size={20} thickness={5} sx={{ color: Colors.GREEN }} /> : null}
                    Imprimer en PDF
                </FormButton>
            
                {
                    isDialog ? 
                    (
                        <IconButton onClick={onClose} sx={{ position: 'absolute', top: 2, right: 2 }}>
                        <CloseOutlined />
                        </IconButton>
                    ):
                    user.role === "contributor" ?

                    (
                        <FormButtonCancel 
                        onClick={()=>   navigate('/'+user?.role+'/questionnaire')} 
                        sx={{ }}>
                            <ChevronLeftOutlined sx={{mr:1}}/> Revenir à l'accueil
                        </FormButtonCancel>
                    )
                    :

                    (
                        <FormButtonCancel 
                        onClick={()=>   navigate('/'+user?.role+'/diagnostics/list')} 
                        sx={{ }}>
                        <ChevronLeftOutlined sx={{mr:1}}/> Liste des Diagnostics
                        </FormButtonCancel>
                    )
                }
               
            </Box>
        </DialogTitle>
        <ScrollingDialogContent
            ref={pdfRef}  
            sx={{
                padding:0,
                /* padding: isDialog ? null : 3, */
                background: Colors.VERY_LIGHT_GREY
            }}
        >
        {/* Other component content */}

        {localResult && (
            <Box>
                <Box className="overview">
                    <Box
                        sx={{
                            background: "white", padding:"5px 15px",
                            display:"flex", justifyContent:"space-between", alignItems:"center"}}
                    >
                        <img src="/logo_white.jpg" width="170px" alt="Logo Keep growing" />
                        <img src="/sage_logo.png" alt="Logo Sage"  width="160px" />
                    </Box>
                    <Card>
                        <CardContent>
                        {/*  <Typography 
                                variant="h3"
                                sx={{
                                    color: Colors.LIGHT_BLUE,
                                    fontSize: "20px",
                                    display:"flex",
                                    gap:"5px",
                                    alignItems:"center",
                                }}
                            >
                                <RadarOutlined/>
                                Synthèse générale
                            </Typography> */}
                            <Box
                                sx={{
                                    px:2,
                                    py:1,
                                    boxShadow:3,
                                    borderRadius:5,
                                    color: "#fff",
                                    backgroundColor: "#fff",
                                    display:"flex",
                                    flexDirection: "row",
                                
                                    alignItems: "center",
                                    justifyContent:"space-between"
                                }}
                            >
                                    <Box>
                                        <Typography 
                                            variant="body1"
                                            sx={{
                                                color: Colors.LIGHT_BLUE,
                                                fontSize: "18px",
                                                fontWeight:"bold"
                                            }}
                                        
                                        >
                                            Votre moyenne générale
                                        </Typography>
                                        <Typography 
                                            variant="body1"
                                            sx={{
                                                color: Colors.TITLE_2,
                                                fontSize: "14px",
                                                fontWeight:"500"
                                            }}
                                        
                                        >
                                            {localResult.recommandation}
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            display:"flex",
                                            justifyContent:"space-between",
                                            alignItems: "center",
                                        
                                        }}
                                    >
                                        <Gauge
                                            width={150} 
                                            height={110} 
                                            value={localResult?.moyenne ?? 0} // Use localResult?.moyenne as the current value
                                            ticks={[-1, 0, 1, 2]} // Set the ticks to the desired values
                                            valueMin={-1} // Set the minimum value of the gauge
                                            valueMax={2} 
                                            startAngle={-90} 
                                            endAngle={90} 
                                            text={
                                                ({ value, valueMax }) => `${value} / ${valueMax}`
                                            }
                                            sx={{
                                                [`& .${gaugeClasses.valueText}`]: {
                                                fontSize: 20,
                                                color: Colors.DARK_GREEN,
                                                transform: 'translate(0px, 0px)',
                                                }, [`& .${gaugeClasses.valueArc}`]: {
                                                    fill:  getColor(localResult?.moyenne ?? 0, true)
                                                    ,
                                                },
                                            }}
                                        
                                        />
                                    </Box>
                            </Box>
                            <Grid container spacing={2} sx={{mt:2}}>
                                <Grid item xs={12} md={6} sx={{
                                    display:"flex",
                                    alignItems:"center",
                                    justifyContent:"start",
                                    flexDirection:"column",
                                    marginTop:"8px"
                                }}>
                                    <Typography 
                                        variant="h5"
                                        sx={{
                                            color: Colors.GREEN,
                                            fontSize: "18px",
                                            fontWeight:"bold"
                                        }}
                                    >
                                        Répartition du score
                                    </Typography>
                                    <GlobalRadarChartComponent sectionScores={localResult.section_scores} />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Box 
                                        sx={{
                                            display:"flex", 
                                            flexDirection:"column",
                                            justifyContent:"center"
                                    }}>

                                        <Box
                                                sx={{
                                                    p:1,
                                                    border: "solid 1px "+ Colors.GREEN,
                                                    borderRadius: "16px"
                                                }}
                                        >
                                                <Typography 
                                                    variant="h5" 
                                                    sx={{
                                                        ml:2,
                                                        color: Colors.GREEN,
                                                        fontSize: "18px",
                                                        fontWeight:"bold"
                                                    }}
                                                >
                                                    Score par Section
                                                </Typography>
                                                <ScrollingBox 
                                                    sx={{
                                                        mt:1,
                                                        /* height:"260px",
                                                        overflowY: "auto", */
                                                        }}
                                                >
                                                    <List 
                                                        sx={{
                                                            p:0,
                                                            width: '100%',
                                                            bgcolor: 'background.paper' 
                                                        }}
                                                    >
                                                        {
                                                            localResult.section_scores.map((section, index)=>
                                                                        <Box>
                                                                            <ListItem 
                                                                                key={section.id}
                                                                                onClick={() => handleScrollToSection(index)} // Handle click to scroll
                                                                                sx={{
                                                                                    display:"flex",
                                                                                    justifyContent:"space-between",
                                                                                    alignItems:"center",
                                                                                    py:"0px"
                                                                                }}
                                                                            >
                                                                                <ListItemText 
                                                                                    primary={section.name} 
                                                                                    secondary={"Section "+(index+1)+" - "+ (section.isAnswered ? "Commencée" : "Pas encore commencée")} 
                                                                                    primaryTypographyProps={{
                                                                                        sx: {
                                                                                        fontWeight: 'bold',
                                                                                        fontSize: '14px', 
                                                                                        color: Colors.TITLE_2, 
                                                                                        lineHeight:1.2
                                                                                        }
                                                                                    }}   
                                                                                    secondaryTypographyProps={{
                                                                                        sx: {
                                                                                        fontSize: '12px', 
                                                                                        color: Colors.TITLE_2, 
                                                                                        lineHeight:1.2
                                                                                        }
                                                                                    }}    
                                                                                />
                                                                            
                                                                                <Chip 
                                                                                    sx={{
                                                                                        backgroundColor: getColor(Math.round((section.score / section.total_questions) * 100) / 100, section.isAnswered),
                                                                                        
                                    
                                                                                        color: "white", // Text color
                                                                                        boxShadow: 3,
                                                                                        minWidth:"100px",
                                                                                        py:"2px",
                                                                                        
                                                                                    }}
                                                                                    
                                                                                    label={
                                                                                        <Typography
                                                                                            sx={{
                                                                                                fontSize:"12px",
                                                                                                fontWeight:"bold"
                                                                                            }}
                                                                                        >
                                                                                            Score <span style={{fontSize:"14px"}}>{Math.round((section.score / section.total_questions) * 100) / 100}</span>
                                                                                        </Typography>
                                                                                    }
                                                                                />
                                                                            
                                                                            </ListItem>
                                                                            <Divider 
                                                                                
                                                                                sx={{
                                                                                    mx:2,
                                                                                    // Thickness of the divider
                                                                                    backgroundColor: Colors.GREEN, // Color of the divider
                                                                                }}
                                                                            />
                                                                            {/*   <Chip label="Détail" size="small" /> */}
                                                                        {/*  </Divider> */}
                                                                        </Box>
                                                                        
                                                                
                                                            )
                                                        }
                                                    </List>
                                                </ScrollingBox>
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Box>


                <Card 
                    variant="outlined" 
                    sx={{
                        mt:2,
                        backgroundColor:"white"
                    }}
                >
                    <CardContent
                        sx={{
                            backgroundColor:"white"
                        }}
                    >
                        <Typography 
                            variant="h3"
                            sx={{
                                color: Colors.LIGHT_BLUE,
                                fontSize: "20px",

                            }}
                        >
                            Analyse détaillée & Recommandations
                        </Typography>
                        <Box sx={{ mt: 2 }}>
  {localResult.sections_subsections_scores.map((section, sectionIndex) => (
    <Box key={section.id} sx={{ mb: 4 }}> {/* Added margin-bottom to separate sections */}
      <Box 
        sx={{bgColor: Colors.MEDIUM_GREEN, borderRadius: "12px", display:"flex", justifyContent:"space-between", flexWrap:"wrap", alignitems:"center"}}
        ref={(el) => sectionRefs.current[sectionIndex] = el}
      >
            <Typography variant="h4" sx={{ color: Colors.TITLE_2, mb: 2 }}>
                Section {sectionIndex + 1} : <span style={{ color: Colors.MEDIUM_GREEN}}>{section.name}</span>
            </Typography>
            <Chip
                    sx={{
                        backgroundColor: getColor(Math.round(section.average_score * 100) / 100, section.isAnswered),
                        color: "white", // Text color
                        boxShadow: 3,
                        minWidth: "100px",
                        py: "2px",

                    }}

                    label={
                        <Typography
                        sx={{
                            fontSize: "12px",
                            fontWeight: "bold"
                        }}
                        >
                        Score <span style={{ fontSize: "14px" }}>{Math.round(section.average_score * 100) / 100}</span>
                        </Typography>
                    }
            />
      </Box>
      
      {section.subsections_scores.map((subsection, index) => (
        /* expanded={expanded != null ? expanded === subsection.id : true}
        onChange={handleChange(subsection.id)} */
        <Box
          key={subsection.id}
          className="section-brief"
          sx={{
            borderRadius: "12px",
            marginY: 1,
            p:2,
            backgroundColor: Colors.VERY_LIGHT_GREY, // Background color when expanded
            border: "solid 1px " + Colors.GREEN,
            boxShadow: 'none'
          }}
        >
            <Box
                /* expandIcon={<ExpandMore />} */
                aria-controls={`${subsection.id}bh-content`}
                id={`${subsection.id}bh-header`}
                sx={{
                backgroundColor: 'transparent',
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap !important",
                marginY: 1,
                minHeight: 'unset',
                /*  '& .MuiAccordionSummary-content': {
                    marginY: 1, // Remove or reduce vertical margin
                },
                '&.Mui-expanded': {
                    minHeight: 'unset', // Reset minHeight to remove extra space
                },
                '& .MuiAccordionSummary-content.Mui-expanded': {
                    marginY: 1, // Reduce vertical margin when expanded
                }, */
                }}
            >
                <Box
                className="section-brief-header"
                sx={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap !important",
                    gap: "5px",
                    flexGrow: 1,
                    flexShrink: 1
                }}
                >
                <Typography sx={{ color: Colors.TITLE_2 }}>
                    Rubrique <span style={{ fontWeight: "bold", color: Colors.MEDIUM_GREEN }}>{index + 1} de {section.subsections_scores.length}</span>
                </Typography>
                <Typography
                    variant="h5"
                    sx={{
                    fontWeight: "bold",
                    flexGrow: 1,
                    flexShrink: 1,
                    color: Colors.MEDIUM_GREEN
                    }}
                >
                    {subsection.name}
                </Typography>
                </Box>
                <Chip
                sx={{
                    backgroundColor: getColor(Math.round(subsection.average_score * 100) / 100, subsection.isAnswered),
                    color: "white", // Text color
                    boxShadow: 3,
                    minWidth: "100px",
                    py: "2px",

                }}

                label={
                    <Typography
                    sx={{
                        fontSize: "12px",
                        fontWeight: "bold"
                    }}
                    >
                    Score <span style={{ fontSize: "14px" }}>{Math.round(subsection.average_score * 100) / 100}</span>
                    </Typography>
                }
                />
            </Box>
            <Box 
                className="section-brief-content"  
                sx={{ backgroundColor: 'transparent' }}
            > {/* Make AccordionDetails transparent */}

                <Grid container spacing={2}>
                {/* Main Grid: 3/4 and 1/4 Layout */}
                <Grid item xs={12} sm={12} md={9}>
                    {/* Content for 3/4 Column */}
                    <Typography
                    variant="h6"
                    sx={{
                        color: Colors.MEDIUM_GREEN,
                        fontSize: "16px",
                        fontWeight: "bold",
                        textAlign: "left",
                        mb: 1

                    }}
                    >
                    Situation
                    </Typography>
                    <Typography
                    component="p"
                    sx={{ fontSize: "14px" }}
                    >
                    {subsection.situation}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                    <Box
                    sx={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",

                    }}
                    >
                        {
                            subsection.isAnswered ?
                            <Gauge
                                width={100}
                                height={75}
                                value={Math.round(subsection.average_score * 100) / 100 ?? 0} // Use localResult?.moyenne as the current value
                                ticks={[-1, 0, 1, 2]} // Set the ticks to the desired values
                                valueMin={-1} // Set the minimum value of the gauge
                                valueMax={2}
                                startAngle={-90}
                                endAngle={90}
                                text={
                                ({ value, valueMax }) => `${value} / ${valueMax}`
                                }
                                sx={{
                                [`& .${gaugeClasses.valueText}`]: {
                                    fontSize: "14px",
                                    color: Colors.DARK_GREEN,
                                    transform: 'translate(0px, 0px)',
                                }, [`& .${gaugeClasses.valueArc}`]: {
                                    fill: getColor(Math.round(subsection.average_score * 100) / 100 ?? 0, subsection.isAnswered)
                                },
                                }}
            
                            />
                            :
                            <Box>
                                <Gauge
                                    width={100}
                                    height={75}
                                    value={-1} // Use localResult?.moyenne as the current value
                                    ticks={[-1, 0, 1, 2]} // Set the ticks to the desired values
                                    valueMin={-1} // Set the minimum value of the gauge
                                    valueMax={2}
                                    startAngle={-90}
                                    endAngle={90}
                                    text={
                                        ({ value, valueMax }) => ` `
                                        }
                                    sx={{ [`& .${gaugeClasses.valueArc}`]: {
                                        fill: getColor(Math.round(subsection.average_score * 100) / 100 ?? 0, subsection.isAnswered)
                                        },
                                    }}
                
                                />
                            <Typography variant="body1"><small>Non commencée</small></Typography>
                        </Box>
                        }
                    
                    </Box>
                </Grid>
                </Grid>

                {/* Nested Grid: Two Columns for Recommendations and Outils */}
                <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12} sm={12}>
                    <Card sx={{ height: "100%" }}>
                    <CardContent sx={{ backgroundColor: Colors.LIGHT_GREEN }}>
                        <Typography
                        variant="h6"
                        sx={{
                            color: Colors.MEDIUM_GREEN,
                            fontSize: "16px",
                            fontWeight: "bold",
                            textAlign: "center",
                            mb: 2

                        }}
                        >
                        Recommandations
                        </Typography>
                        <Typography component="ul" sx={{ paddingLeft: 0, fontSize: '14px' }}>
                        {subsection.recommendation?.split('\\n').map((line, index) => (
                            <li key={index} style={{ marginBottom: "5px", listStyle: 'none', paddingLeft: 0, marginLeft: 0 }}>
                            <span style={{ fontWeight: 'bold', color: Colors.DARK_GREEN }}>
                                {index + 1} -</span> {line}
                            </li>
                        ))}
                        </Typography>
                    </CardContent>
                    </Card>
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                    <Card  sx={{ height:"100%"}}>
                        <CardContent>
                            <Typography 
                                    variant="h6"
                                    sx={{
                                        color: Colors.MEDIUM_GREEN,
                                        fontSize: "16px",
                                        fontWeight:"bold",
                                        textAlign:"center",
                                        mb:2
                                    }}
                            >
                                Outils
                            </Typography>
                            <Typography component="ul" sx={{ paddingLeft: 0, fontSize: '14px' }}>
                                {section.outils?.split('\\n').map((line, index) => (
                                <li key={index} style={{ marginBottom:"5px",listStyle: 'none', paddingLeft: 0, marginLeft: 0 }}>
                                    <span style={{ fontWeight: 'bold', color: Colors.DARK_GREEN }}>
                                    {index + 1} -</span> {line}
                                </li>
                                ))}
                            </Typography>
                        </CardContent>
                    </Card>
                
                </Grid> */}
                </Grid>
          </Box>
        </Box>
      ))}
    </Box>
  ))}
</Box>
                                   
                    </CardContent>
                </Card>
            </Box>
           
        )}

      
      
        </ScrollingDialogContent>
       {/*  <DialogActions
          sx={{ px: 2, py: 1 }}
          style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center' }}
        >
            <FormButtonCancel variant="outlined" onClick={onClose}>Annuler</FormButtonCancel>
            <FormButtonCancel onClick={onSubmit} disabled={loading}>
              {loading ? <CircularProgress size={20} thickness={5} sx={{ color: Colors.GREEN }} /> : "Confirmer l'affectation"}
            </FormButtonCancel>
        </DialogActions> */}
      </Dialog>
    );
};
  

function GaugePointer() {
    const { valueAngle, outerRadius, cx, cy } = useGaugeState();
  
    if (valueAngle === null) {
      // No value to display
      return null;
    }
  
    const target = {
      x: cx + outerRadius * Math.sin(valueAngle),
      y: cy - outerRadius * Math.cos(valueAngle),
    };
    return (
      <g>
        <circle cx={cx} cy={cy} r={5} fill="#3FB4B1" />
        <path
          d={`M ${cx} ${cy} L ${target.x} ${target.y}`}
          stroke="#3FB4B1"
          strokeWidth={3}
        />
      </g>
    );
  }
  export default ResultsModal;