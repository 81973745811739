// DiagnosissCounter.js

import React, { useEffect, useState } from 'react';
import { Card, CardContent, Box, Typography, Grid } from '@mui/material';
import useAxios from 'src/services/apiInterceptor';
import Colors from 'src/styles/Colors';
import { BarChartOutlined, GroupAddOutlined } from '@mui/icons-material';
import { fetchStats } from 'src/store/actions/statsActions';
import { useDispatch, useSelector } from 'react-redux';

const DiagnosisStatsBar = () => {
    const dispatch = useDispatch();

    const {
        diagnosisCount,
        personalDiagnosisCount,
        teamsWithUsersPassingSurveyCount,
        loading,
        error
    } = useSelector((state) => state.stats);

    useEffect(() => {
        dispatch(fetchStats());
    }, [dispatch]);

    /* if (loading) {
        return <Typography color="#fff">Chargement...</Typography>;
    }

    if (error) {
        return <Typography color="error">Erreur lors du chargement des données: {error}</Typography>;
    }
 */
    /* if (loading) return <Typography>Loading...</Typography>;
    if (error) return <Typography color="error">Error fetching diagnosis count</Typography>; */

    return (
        <Grid container spacing={2} sx={{ alignItems: 'stretch' }}>
            {/* First Column */}
            <Grid item xs={6}  sm={6} md={4} sx={{ minHeight: '80px' }}>
                <Card
                onClick={() => null}
                sx={{
                    borderRadius: 4,
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    background: Colors.LIGHT_BLUE,
                    cursor:"pointer"
                }}
                >
                    <CardContent 
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'start', 
                            padding:"6px !important",
                            gap: '5px'
                    }}>
                    {/* Counter */}
                    <Box
                                sx={{
                                    padding: '10px',
                                    bgcolor: Colors.MEDIUM_GREEN,
                                    borderRadius: '10px',
                                    color: '#fff',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <BarChartOutlined fontSize="medium" style={{ color: "#fff" }} />
                                
                            </Box>

                            {/* Typography */}
                            <Typography
                            color={"#fff"}
                            variant="h6"
                            component="div"
                            gutterBottom
                            style={{ maxWidth: '70%', lineHeight: '1.3', marginBottom:0 }}
                            >
                                Liste des diagnostics
                            </Typography>                    
                    </CardContent>
                </Card>
                
            </Grid>
            <Grid item xs={12} sm={6} md={4} sx={{ minHeight: '100px' }}>
                <Card
                    sx={{
                        borderRadius: 4,
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        background:"#F6F6F6",
                        boxShadow: 2
                    }}
                >
                    <CardContent
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'start',
                            padding: "6px !important",
                            gap: '5px'
                        }}
                    >
                        {/* Counter */}
                        <Box
                            sx={{
                                padding: '8px 16px',
                                bgcolor: Colors.MEDIUM_GREEN,
                                borderRadius: '10px',
                                color: '#fff',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Typography 
                                variant="h6" component="div">
                                {personalDiagnosisCount || 0}
                            </Typography>
                        </Box>

                        {/* Typography */}
                        <Typography
                            color={"#fff"}
                            variant="h6"
                            component="div"
                            gutterBottom
                            style={{ 
                                color:Colors.LIGHT_BLUE,
                                maxWidth: '70%',
                                lineHeight: '1.3',
                                marginBottom: 0 }}
                        >
                            Diagnostics personnels
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}  sm={6} md={4} sx={{ minHeight: '100px' }}>
                <Card
                    sx={{
                        borderRadius: 4,
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        background:"#F6F6F6",
                        boxShadow: 2
                    }}
                >
                    <CardContent
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'start',
                            padding: "6px !important",
                            gap: '5px'
                        }}
                    >
                        {/* Counter */}
                        <Box
                            sx={{
                                padding: '8px 16px',
                                bgcolor: Colors.MEDIUM_GREEN,
                                borderRadius: '10px',
                                color: '#fff',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Typography 
                                variant="h6" component="div">
                                {diagnosisCount || 0}
                            </Typography>
                        </Box>

                        {/* Typography */}
                        <Typography
                            color={"#fff"}
                            variant="h6"
                            component="div"
                            gutterBottom
                            style={{ 
                                color:Colors.LIGHT_BLUE,
                                maxWidth: '70%',
                                lineHeight: '1.3',
                                marginBottom: 0 }}
                        >
                            Experts comptables en diagnostic
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
           {/*  <Grid item xs={6}  sm={6} md={3} sx={{ minHeight: '80px' }}>
                <Card
                    sx={{
                        borderRadius: 4,
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        background:"#F6F6F6",
                        boxShadow: 2
                    }}
                >
                    <CardContent
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'start',
                            padding: "6px !important",
                            gap: '5px'
                        }}
                    >
                        <Box
                            sx={{
                                padding: '8px 16px',
                                bgcolor: Colors.MEDIUM_GREEN,
                                borderRadius: '10px',
                                color: '#fff',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Typography 
                                variant="h6" component="div">
                                {teamsWithUsersPassingSurveyCount || 0}
                            </Typography>
                        </Box>

                        <Typography
                            color={"#fff"}
                            variant="h6"
                            component="div"
                            gutterBottom
                            style={{ 
                                color:Colors.LIGHT_BLUE,
                                maxWidth: '70%',
                                lineHeight: '1.3',
                                marginBottom: 0 }}
                        >
                            Cabinets comptables en Diagnostic
                        </Typography>
                    </CardContent>
                </Card>
            </Grid> */}
            

            {/* Second Column */}
            {/*   <Grid item xs={12} sm={6}>
                <TeamsHorizontalBarChart />
            </Grid> */}
        </Grid>
      
    );
};

export default DiagnosisStatsBar;
