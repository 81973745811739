import { Box, Card, CardContent, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAxios from "src/services/apiInterceptor";
import { addOrUpdateUserSurvey } from "src/store/actions/currentUserSurveysActions";
import Colors from "src/styles/Colors";
import { FormButton } from "src/styles/CustomStyles";


const ContributorSurveyMain = ({handleCommencer, survey}) =>{
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const api = useAxios();
    const [loading, setLoading] =useState(false);

    useEffect(()=>{
        /* handleCommencer(survey); */
        console.log(survey)
        console.log("isStarted => ", survey.isStarted)
    },[])

    const showResults = async () =>{
        try {
            setLoading(true)

            const response = await api.get(`/surveys/check_current_user_survey/${survey.id}`, {
                                headers: {
                                    'Content-Type': 'application/json',
                                }
                            });
    
            /*    const result = await response.json(); */
            console.log(response.data)
            if (response.data.exists) {
                console.log(response.data.users_surveys)
                 // Add the survey to Redux state, avoiding redundancy
                dispatch(addOrUpdateUserSurvey(response.data.users_surveys));
             
                navigate('/contributor/diagnostics/resultat/'+response.data.users_surveys.id);
            } else {
              //dispatch(showSnackbar("Essayez d'abord de commencer le diagnostic afin de pouvoir prévisualiser vos résultats"))
            }
        } catch (error) {
            console.error('Error checking user survey:', error);
        }finally{
            setLoading(false)
        }
    }

    return (
        <Card sx={{flexGrow:1, display:"flex", flexDirection:"column",justifyContent:"center", alignItems:"center"}}>
            <CardContent sx={{flexGrow:1,display:"flex", justifyContent:"space-around", flexDirection:"column", alignItems:"center"}}>
                <Box sx={{ display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                    
                    <img src='/sage_logo.png' alt="Logo Sage" width="200px"/>
                </Box>
                <Box  sx={{maxWidth:"80%"}}>

                    <Typography variant="h4" sx={{textAlign:"center"}}>
                            Bienvenue dans le Check-Up de Change by Sage, votre outil de diagnostic personnalisé.
                    </Typography>

                    <Typography variant="body1" sx={{mt:5, textAlign:"center"}}>
                            Ce questionnaire couvre 10 dimensions clés de votre cabinet à travers 146 questions ciblées. Prenez le temps de répondre avec honnêteté et précision ; cela vous prendra environ 20 à 25 minutes. 
                    </Typography>
                    <Typography variant="body1" sx={{mt:1, textAlign:"center"}}>
                        Vos réponses sont essentielles pour générer des recommandations pertinentes qui vous aideront à transformer votre pratique et à relever les défis de demain.
                    </Typography>
                </Box>
                <Box sx={{ display:"flex", justifyContent:"center", alignItems:"center"}}>
                    <FormButton size="large" onClick={() => survey.isFinished === true ? showResults() : handleCommencer(survey) }>
                        
                        { loading && <CircularProgress size={20} thickness={5} sx={{ color: Colors.GREEN }} />}
                        
                        {
                            survey.isStarted === null ||
                            survey.isStarted === false ?
                            "Faire votre diagnostique"
                            :
                            survey.isFinished === false ?
                            "Poursuivre votre diagnostique"
                            :
                            "Afficher vos résultats"
                            

                        }
                        
                        </FormButton>
                </Box>
                
            </CardContent>
        
        </Card>
    );

};

export default ContributorSurveyMain;