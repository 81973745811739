import React, { useEffect, useRef, useState } from 'react';
import {
  Box, Table, TableBody, TableCell, TableHead, TableRow, Select, MenuItem,
  InputLabel, IconButton, Menu, CircularProgress, Typography,
  Tooltip
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import {
  fetchTeams, setCurrentPage, setFilters, setPerPage, setSortBy, setSortOrder
} from '../../../store/actions/teamActions';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import * as XLSX from "xlsx";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import InsertchartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import {
  FiltersTextField, FilterListFormControl, FilterButton
} from 'src/styles/CustomStyles';

import { API_URL } from 'src/config';
import { showSnackbar } from 'src/store/actions/snackbarActions';
import useAxios from 'src/services/apiInterceptor';
import EditTeamModal from './Modals/EditTeamModal';
import DeleteTeamModal from './Modals/DeleteTeamModal';
import { CustomTablePagination } from 'src/styles/CustomStyles';
import Colors from 'src/styles/Colors';
import { GroupOutlined, RefreshOutlined, UploadFileOutlined } from '@mui/icons-material';
import ShowCollaboratorsModal from './Modals/ShowCollaboratorsModal';

const exportToExcel = (data) => {
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet(data);
  XLSX.utils.book_append_sheet(wb, ws, 'Teams Data');
  XLSX.writeFile(wb, 'teams_data.xlsx');
};

const EquipeList = () => {
    const dispatch = useDispatch();
    const {
      teams,
      totalPages,
      currentPage,
      filters,
      sortBy,
      sortOrder,
      perPage,
      loading,
      error
    } = useSelector((state) => state.teams);
  
    const [page, setPage] = useState(currentPage - 1);
    const [rowsPerPage, setRowsPerPage] = useState(perPage);
    
    const [errors, setErrors] = useState({});
    const api = useAxios();
    const [loadingSubmit, setLoadingSubmit] = useState(false); // Add loading state
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    
    
    
    useEffect(() => {
      dispatch(fetchTeams(page + 1));
    }, [dispatch, filters, sortBy, sortOrder, rowsPerPage, page]);
  
    useEffect(() => {
      if (page >= totalPages && totalPages > 0) {
        setPage(totalPages - 1);
      }
    }, [totalPages, page]);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
      dispatch(setCurrentPage(newPage + 1));
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      dispatch(setPerPage(parseInt(event.target.value, 10)));
      setPage(0);
    };
    const handleClearFilters = () => {
        dispatch(setFilters({}));
        dispatch(setSortBy('id'));
        dispatch(setSortOrder('desc'));
    };
  
   
   /*  const handleClose = () => {
      setOpen(false);
      setTeamData({ name: '', department: '', max_collaborators: 0 });
      setErrors({});
    }; */
  
  
  
   
  
  
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [selectedTeam, setSelectedTeam] = useState(null);
  
    const handleEditClick = (team) => {
      setSelectedTeam(team);
      setEditModalOpen(true);
    };
  
    const handleEditModalClose = () => {
      setEditModalOpen(false);
      setSelectedTeam(null);
    };
  
    const handleEditSubmit = async () => {
        setLoadingSubmit(true); // Start loading
        // Reset errors
      
        setErrors({});
      try {
        const updateData = {
          name: selectedTeam.name,
          department: selectedTeam.department,
          max_collaborators: selectedTeam.max_collaborators,
          status: "active"
        }
          await api.put(`${API_URL}/teams/${selectedTeam.id}`, updateData);
          dispatch(fetchTeams(page + 1)); // Refresh teams list
          handleEditModalClose(); // Close the modal
        } catch (error) {
          console.error("Failed to update team:", error);
          if (error?.response?.data) {
            const validationErrors = error.response.data || {}; // Adjust based on your API response structure
            setErrors(validationErrors);
            dispatch(showSnackbar('Échec de la mise à jour du cabinet comptable id: '+selectedTeam.id+" "+selectedTeam.name, 'error'));
          } else {
              dispatch(showSnackbar('Échec de la mise à jour du cabinet comptable id: '+selectedTeam.id+" "+selectedTeam.name+' '+ error.message , 'error'));
          }
      }
      setLoadingSubmit(false); // Start loading
     
    };
  
    const handleDeleteClick = (team) => {
      setSelectedTeam(team);
      setDeleteModalOpen(true);
    };
  
    const handleDeleteModalClose = () => {
      setDeleteModalOpen(false);
      setSelectedTeam(null);
    };
  
    const handleDeleteSubmit = async () => {
      setLoadingSubmit(true);
      try {
        await api.delete(`${API_URL}/teams/${selectedTeam.id}`);
        dispatch(showSnackbar('Cabinet supprimé avec succés', 'success'));
        dispatch(fetchTeams());
        handleDeleteModalClose();
      } catch (error) {
        console.error("Failed to delete team:", error);
        dispatch(showSnackbar('Échec de la suppression du cabinet comptable', 'error'));
      } finally {
        setLoadingSubmit(false);
      }
    };
  
    const [anchorEl, setAnchorEl] = useState(null);
    const handleMenuClick = (event, team) => {
      setSelectedTeam(team);
      setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedTeam(null);
  
    };


    
    const fileInputRef = useRef(null);
  
    const handleFileUpload = async (event) => {
        setLoadingSubmit(true);
        const file = event.target.files[0];
        if (file) {
        const formData = new FormData();
        formData.append('file', file);
  
        try {
            const response = await api.post(`${API_URL}/teams/uploadUsersFile/${selectedTeam.id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
            });
  
            // Handle response if needed
            console.log('File uploaded successfully:', response.data);
           // fileInputRef.current.value = null;
            dispatch(showSnackbar('File uploaded successfully : '+ response.data, 'success'));
          
        } catch (error) {
          console.error("Erreur lors du téléversement du fichier:", error);
          if (error?.response?.data) {
            const validationErrors = error.response.data.error || {}; // Adjust based on your API response structure
           
            dispatch(showSnackbar('Erreur lors du téléversement du fichier'+ validationErrors, 'error'));
          } else {
            dispatch(showSnackbar('Erreur lors du téléversement du fichier'+ error.message, 'error'));
          }
          
        } finally {
          setLoadingSubmit(false);
          fileInputRef.current.value = null;
  
        }
      }
     
    };
  
    const handleUploadClick = () => {
        fileInputRef.current.click();
    };
    const handleDownloadClick = () => {
        api.get('/teams/download/template', {
            responseType: 'blob' // Specify that we expect a blob response
        })
        .then(response => {
            // Create a URL for the blob object
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));
            
            // Create a link element
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'template_import_collabroateurs.csv'); // Set default file name
    
            // Append the link to the body
            document.body.appendChild(link);
    
            // Programmatically click the link to trigger the download
            link.click();
    
            // Clean up and remove the link
            link.parentNode.removeChild(link);
        })
        .catch(error => {
            console.error('Error downloading the file:', error);
        });
    };
    

    const [collaboratorsModalOpen, setCollaboratorsModalOpen] = useState(false);

    const handleCollaboratorsClick = (team) => {
        setSelectedTeam(team);
        setCollaboratorsModalOpen(true);
    };

    const handleCollaboratorsModalClose = () => {
        setCollaboratorsModalOpen(false);
        setSelectedTeam(null);
    };
  

  return (
    <Box>
       {/* Teams Table */}
       <Box>
        <Box mb={2} style={{ display: "flex", flexWrap:"wrap", justifyContent: "space-between", alignItems: "center" }}>
          <Typography variant="h6" gutterBottom color="textPrimary">
            Liste des Cabinets comptables
          </Typography>

          <Box display="flex" flexWrap="wrap" gap={2}>
            <FiltersTextField
              label="Nom"
              variant="outlined"
              size="small"
              value={filters.name || ''}
              onChange={(e) => dispatch(setFilters({ ...filters, name: e.target.value }))}
            />
            <FiltersTextField
              label="Département"
              variant="outlined"
              size="small"
              value={filters.department || ''}
              onChange={(e) => dispatch(setFilters({ ...filters, department: e.target.value }))}
            />
            <FilterListFormControl size="small">
              <InputLabel>Tri Par</InputLabel>
              <Select
                value={sortBy}
                label="Tri par"
                onChange={(e) => dispatch(setSortBy(e.target.value))}
              >
                <MenuItem value="id">ID</MenuItem>
                <MenuItem value="name">Nom</MenuItem>
                <MenuItem value="department">Département</MenuItem>
                <MenuItem value="max_collaborators">Max Experts comptables</MenuItem>
                <MenuItem value="created_at">Créé Le</MenuItem>
              </Select>
            </FilterListFormControl>
            <FilterListFormControl size="small">
              <InputLabel>Ordre de Tri</InputLabel>
              <Select
                label="Ordre de Tri"

                value={sortOrder}
                onChange={(e) => dispatch(setSortOrder(e.target.value))}
              >
                <MenuItem value="asc">Ascendant</MenuItem>
                <MenuItem value="desc">Descendant</MenuItem>
              </Select>
            </FilterListFormControl>
            <Tooltip title="Rafraichir et vider les filtres">
                <IconButton onClick={handleClearFilters} color="primary">
                    <RefreshOutlined />
                </IconButton>
            </Tooltip>
            
            {/* Export Button */}
            <Tooltip title="Exporter sous format Excel">
                <FilterButton
                variant="contained"
                color="primary"
                startIcon={<FileDownloadOutlinedIcon />}
                onClick={() => exportToExcel(teams)}
                >
                Exporter
                </FilterButton>
            </Tooltip>
          
          </Box>
        </Box>

        {/* Table Container */}
        <Box sx={{ flexGrow: 1, overflowX: 'auto', mt: 2 }}>
          <Table sx={{ width: '100%', background: "#fff", borderRadius: "15px" }}>
            <TableHead>
              <TableRow>
                <TableCell className="center-align">ID</TableCell>
                <TableCell className="center-align">Nom</TableCell>
                <TableCell className="center-align">Département</TableCell>
                <TableCell className="center-align">Nbr Expert comptables</TableCell>
                <TableCell className="center-align">Max Experts comptable</TableCell>
                <TableCell className="center-align">Manager</TableCell>
                <TableCell className="center-align">Crée le</TableCell>
                <TableCell className="center-align">Modifié le</TableCell>
                <TableCell className="center-align">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow style={{ height: 300 }}>
                  <TableCell colSpan={7} align="center">
                    <Typography>Loading...</Typography>
                  </TableCell>
                </TableRow>
              ) : error ? (
                <TableRow style={{ height: 300 }}>
                  <TableCell colSpan={7} align="center">
                    <Typography>Error: {error}</Typography>
                  </TableCell>
                </TableRow>
              ) : (
                teams.map((team) => (
                  <TableRow key={team.id}>
                    <TableCell className="center-align">{team.id}</TableCell>
                    <TableCell className="center-align">{team.name}</TableCell>
                    <TableCell className="center-align">{team.department}</TableCell>
                    <TableCell className="center-align">{team.collaborators_count}</TableCell>
                    <TableCell className="center-align">{team.max_collaborators}</TableCell>
                    <TableCell className="center-align">{team.manager.first_name} {team.manager.last_name}</TableCell>
                    <TableCell className="center-align">
                        {new Date(team.created_at).toLocaleString('fr-FR', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit'
                        })}
                      </TableCell>
                      <TableCell className="center-align">
                        {new Date(team.updated_at).toLocaleString('fr-FR', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit'
                        })}
                      </TableCell>
                    <TableCell sx={{ position: 'sticky', right: 0, background: '#fff' }}>
                        <Tooltip title="Afficher les experts comptables">
                            <IconButton 
                                sx={{ color: Colors.MEDIUM_GREEN }}
                                onClick={() => handleCollaboratorsClick(team)}
                            >
                                <GroupOutlined />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Mettre à jour le cabinet comptable">
                            <IconButton color="default" onClick={() => handleEditClick(team)}>
                                <EditOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                        
                        <Tooltip title="Supprimer définitivement">
                            <IconButton sx={{ color: "#ff0000"}} onClick={() => handleDeleteClick(team)}>
                                <DeleteOutlinedIcon />
                            </IconButton>
                        </Tooltip>

                        {/* <Tooltip title="Importer un fichier .csv des collaborateurs .csv">
                            <IconButton
                                sx={{ color: Colors.LIGHT_BLUE}}
                                onClick={(event) => handleUploadClick(event, team)}
                            >
                                <UploadFileOutlined/>
                            </IconButton>
                        </Tooltip> */}
                       
                        <Tooltip title="Plus d'options">
                            <IconButton
                                onClick={(event) => handleMenuClick(event, team)}
                            >
                                <MoreVertOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                            <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                                PaperProps={{
                                    style: {
                                        width: 200,
                                    },
                                }}
                            >
                                <MenuItem sx={{fontSize:"14px"}} onClick={handleUploadClick}>
                                {loadingSubmit ? (
                                    <CircularProgress size={20} thickness={5} sx={{ color: Colors.GREEN }} />
                                ) : (
                                    <UploadFileOutlined sx={{mr:1}}/>
                                )}
                                Importer des experts comptables en masse
                                </MenuItem>
                                <MenuItem sx={{fontSize:"14px"}} onClick={handleDownloadClick}>
                                {loadingSubmit ? (
                                    <CircularProgress size={20} thickness={5} sx={{ color: Colors.GREEN }} />
                                ) : (
                                    <FileDownloadOutlinedIcon sx={{mr:1}}/>
                                )}
                                    Télécharger la Template d'ajout en masse
                                </MenuItem>
                            </Menu>
                        
                  </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
        </Table>
                {/* Hidden file input */}
        <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            accept=".csv" // Ensure only CSV files are selected
            onChange={handleFileUpload}
        />
        </Box>

        {/* Pagination */}
        <CustomTablePagination
          rowsPerPageOptions={[10, 25,50,100]}
          component="div"
          count={totalPages * rowsPerPage}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Afficher"
          nextIconButtonProps={{
            'aria-label': 'Page suivante',
          }}
          backIconButtonProps={{
            'aria-label': 'Page précédente',
          }}
        />
      </Box>

       
      
      {/* Edit Team Modal */}
      {selectedTeam && (
        <EditTeamModal
          open={editModalOpen}
          onClose={handleEditModalClose}
          onSubmit={handleEditSubmit}
          teamData={selectedTeam}
          setTeamData={setSelectedTeam}
          loading={loadingSubmit} // Pass loading state
          errors={errors} // Pass any validation errors here
        />
      )}
       {/* Delete Team Modal */}
       {selectedTeam && (
        <DeleteTeamModal
          open={deleteModalOpen}
          onClose={handleDeleteModalClose}
          onSubmit={handleDeleteSubmit}
          teamData={selectedTeam}
          loading={loadingSubmit}
        />
      )}

      {/* Collaborators Modal */}
      {selectedTeam && (
        <ShowCollaboratorsModal
          open={collaboratorsModalOpen}
          onClose={handleCollaboratorsModalClose}
          teamId={selectedTeam.id}
        />
      )}
    </Box>
  );
};

export default EquipeList;
