import React from 'react';
import { Snackbar, Alert } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import Colors from 'src/styles/Colors';
import { hideSnackbar } from 'src/store/actions/snackbarActions';

const GlobalSnackbar = () => {
  const dispatch = useDispatch();
  const { open, message, severity } = useSelector((state) => state.snackbar);

  const handleClose = () => {
    dispatch(hideSnackbar());
  };

  return (
    <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Position at top center
        sx={{ 
        '& .MuiSnackbarContent-root': {
            borderRadius: '8px',
        },
        '& .MuiAlert-root': {
            fontWeight: 'bold',
        },
        }}
    >
        <Alert
        onClose={handleClose}
        severity={severity}
        sx={{ width: '100%', bgcolor: Colors[severity.toUpperCase()] }}
        >
        {message}
        </Alert>
    </Snackbar>
  );
};

export default GlobalSnackbar;
