import { createStore, applyMiddleware, compose } from 'redux';
import { thunk } from 'redux-thunk';
import rootReducer from './reducers';
// Load state from localStorage
const loadState = () => {
  try {
    const serializedState = localStorage.getItem('currentUserSurveys');
    if (serializedState === null) return [];
    return JSON.parse(serializedState);
  } catch (err) {
      console.error(err)
    return [];
  }
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  {
    currentUserSurveys: loadState(), // Initialize state from localStorage
  },
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
