// SurveysCounter.js

import React, { useEffect, useState } from 'react';
import { Card, CardContent, Box, Typography } from '@mui/material';
import useAxios from 'src/services/apiInterceptor';
import Colors from 'src/styles/Colors';

const SurveysCounter = () => {
    const [surveyCount, setSurveyCount] = useState(0);
   
    const api = useAxios();
    useEffect(() => {
        // Fetch the survey count from the API
        const fetchSurveyCount = async () => {
            try {
                const response = await api.get('/surveys/manager/count');
                setSurveyCount(response.data.count);
        
            } catch (err) {
                console.error(err.message)
            }
        };

        fetchSurveyCount();
    }, [surveyCount]);

    /* if (loading) return <Typography>Loading...</Typography>;
    if (error) return <Typography color="error">Error fetching survey count</Typography>; */

    return (
        <Card
            sx={{
                borderRadius: 4,
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                background: Colors.LIGHT_BLUE,
            }}
        >
            <CardContent
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'start',
                    padding: "6px !important",
                    gap: '3px'
                }}
            >
                {/* Counter */}
                <Box
                    sx={{
                        padding: '8px 16px',
                        bgcolor: Colors.MEDIUM_GREEN,
                        borderRadius: '10px',
                        color: '#fff',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography variant="h6" component="div">
                        {surveyCount || 0}
                    </Typography>
                </Box>

                {/* Typography */}
                <Typography
                    color={"#fff"}
                    variant="h6"
                    component="div"
                    gutterBottom
                    style={{ maxWidth: '70%', lineHeight: '1.3', marginBottom: 0 }}
                >
                    Liste des Questionnaires
                </Typography>
            </CardContent>
        </Card>
    );
};

export default SurveysCounter;
