import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ResultsModal from "./Modal/ResultsModal";

const RapportResultat = () => {
    const { id } = useParams(); // Get the id from the URL
    const navigate = useNavigate(); // For navigation
    const [message, setMessage] = useState('Hello, world!');
  
    // Replace this with your actual validation logic
    const isValidId = (id) => {
      // Example validation logic, replace with your own
      return id && !isNaN(id); // Just a simple example, adapt as needed
    };
  
    useEffect(() => {
      if (!isValidId(id)) {
        navigate('/manager/diagnostics/list'); // Redirect if the ID is not valid
      }
    }, [id, navigate]);
  
    const handleClick = () => {
      setMessage('Button clicked!');
    };
  
    return (
      <div>
        <ResultsModal
              open={true}
              onClose={null}
              onSubmit={null}
              loading={false}
              diagnosis={{"id": id}}
              isDialog={false}
        />
       
      </div>
    );
}

export default RapportResultat;