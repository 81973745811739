import React from 'react';
import Layout from '../_layout/Layout';


const Admin = () => {
  return (
    <Layout role="admin">
     
    </Layout>
  );
};

export default Admin;
