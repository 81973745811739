import React, { useState } from 'react';
import { Box, TextField, Button, Typography, IconButton, CircularProgress } from '@mui/material';
import axios from 'axios';
import useAxios from 'src/services/apiInterceptor';
import { Link, useNavigate } from 'react-router-dom';
import { ContactMail } from '@mui/icons-material';
import { showSnackbar } from 'src/store/actions/snackbarActions';
import { useDispatch } from 'react-redux';
import { FormButtonCancel } from 'src/styles/CustomStyles';
import { FormButton } from 'src/styles/CustomStyles';
import Colors from 'src/styles/Colors';
import { FormTextField } from 'src/styles/CustomStyles';

const Contact = ({ role }) => {
    const [formData, setFormData] = useState({
        subject: '',
        message: ''
    });

    
    const [loading, setloading] = useState(false);
    const api = useAxios();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
      
        setloading(true)

        try {
            const response = await api.post('/dashboard/contact', formData);
            if (response.status === 200) {
               // setSuccess('Votre message a été envoyé avec succès.');
            dispatch( showSnackbar('Votre message a été envoyé avec succès.', "success"));
                
                setFormData({
                    subject: '',
                    message: ''
                });
                dispatch( showSnackbar('Votre message a été envoyé avec succès.', "success"));

                navigate('/' + role + '/dashboard');
            }
        } catch (error) {
            dispatch( showSnackbar('Une erreur est survenue. Veuillez réessayer plus tard.', "error"));
        } finally {
            setloading(false)
        }
    };

    return (
        <Box sx={{ maxWidth: '700px', margin: '0 auto', mt: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
                <IconButton aria-label="contact" sx={{ mr: 1 }}>
                    <ContactMail />
                </IconButton>
                <Typography variant="h4" gutterBottom>
                    Contactez-nous
                </Typography>
            </Box>
            <Typography variant="body1" sx={{ mb: 3 }}>
                Vous avez besoin d'assistance ? laissez nous votre demande, un de nos agents vous contactera en moins de 48 heures.
            </Typography>

            <form onSubmit={handleSubmit}>
                <FormTextField
                    fullWidth
                    margin="normal"
                    label="Demande (Sujet)"
                    name="subject"
                    value={formData.subject}
                    onChange={handleChange}
                    required
                />
                <FormTextField
                    fullWidth
                    margin="normal"
                    label="Message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    multiline
                    rows={5}
                    required
                />

        <Box
          display="flex"
          flexWrap={"wrap"}
          justifyContent={"space-between"}
          alignItems="center"
          gap={1}
          sx={{mt:3}}
        >
          <FormButtonCancel variant="outlined" 
            component={Link}
            to={`/manager/dashboard`}
          >Retour</FormButtonCancel>
          <FormButton  type="submit" disabled={loading}>
            {loading ? <CircularProgress size={20} thickness={5} sx={{ color: Colors.GREEN, mr:2}} />  : null } Envoyer
          </FormButton>
        </Box>
                
            </form>
        </Box>
    );
};

export default Contact;
