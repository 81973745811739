import React from 'react';
import { Container } from '@mui/material';

const QuestionnaireAdmin = () => {
  return (
    <Container sx={{ mt: 2 }}>
      <h1>Rôle Admin Questionnaire </h1>
      <p>Manage questionnaires here.</p>
      {/* Add content for managing questionnaires */}
    </Container>
  );
};

export default QuestionnaireAdmin;
