import './index.css';
import React from 'react';
/* import ReactDOM from 'react-dom'; */
import { Provider } from 'react-redux';
import store from './store/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
import {ThemeProvider } from '@mui/material';
import theme from './styles/Theme';
import GlobalSnackbar from './components/_shared-components/GlobalSnackbar';

const container = document.getElementById('root');
const root = createRoot(container); // 


root.render(
  <Provider store={store}>
    
    <ThemeProvider theme={theme}>
      <GlobalSnackbar />
      <App style={{ margin: 0 }}/>
     </ThemeProvider>
  </Provider>
)

/* ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
); */



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
