import { Box, Card, CardContent, Typography,LinearProgress  } from "@mui/material";
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import Colors from "src/styles/Colors";
import { FormButton } from "src/styles/CustomStyles";
import { useDispatch, useSelector } from 'react-redux';
import { removeUserSurvey } from "src/store/actions/currentUserSurveysActions";
import useAxios from "src/services/apiInterceptor";
import { showSnackbar } from "src/store/actions/snackbarActions";
import { addOrUpdateUserSurvey } from "src/store/actions/currentUserSurveysActions";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { FormButtonCancel } from "src/styles/CustomStyles";
import SurveyFinished from "./components/SurveyFinished";
import { ChevronLeftOutlined } from "@mui/icons-material";

const QuestionnaireReponse = ({role}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { selectedSurvey } = location.state || {}; // Destructure selectedSurvey
    const currentUserSurveys = useSelector(state => state.currentUserSurveys); // Get surveys from Redux state
    const api = useAxios();
    const [question, setQuestion] = useState(null); // State to hold question data
    const [activeChoice, setActiveChoice] = useState(null); // State to hold question data
    const [answer, setAnswer] = useState(null); // State to hold question data
    const [previous, setPrevious] = useState(true); // State to hold question data
    const [isFinished, setIsFinished] = useState(null); // State to hold question data
    const [loadingQuestion, setLoadingQuestion] = useState(false);
    useEffect(() => {
        if (!selectedSurvey && role) {
            navigate('/'+role+'/questionnaire/list');
            return;
        }
        console.log("use effect selectedSurvey.survey_id => ", selectedSurvey.id)

        // Check if the selected survey exists in the currentUserSurveys
       const existingSurvey = currentUserSurveys.find(survey => survey.survey_id === selectedSurvey.id);
        console.log("use effect currentUserSurveys=> ", currentUserSurveys)
        console.log("use effect existingSurvey=> ", existingSurvey)

        if (existingSurvey) {
            if(existingSurvey.status === "finished"){
                setIsFinished(true)
            }
            console.log('Current User Active Survey:', existingSurvey);
            console.log('Current User Active Survey: ID', existingSurvey.id);

        } else {
            // Optionally add the survey if it doesn't exist
         //   dispatch(addUserSurvey({ survey_id: selectedSurvey.id, ...selectedSurvey }));
        }
    }, [selectedSurvey, navigate, dispatch, currentUserSurveys]);

    const handleContinue = async () => {
        // Logic for continuing the survey
        /* alert("null currentQuestion") */
        let question_id = 0;
        if(existingSurvey.current_question_id != null ) {
            question_id = existingSurvey.current_question_id;
            await getQuestion(question_id)
        }else{
            await getQuestionByOrder(1)
        }
       
        navigate('/'+role+'/questionnaire/passer', { state: { selectedSurvey } });
    };

    const handleRestart = () => {
        // Logic for restarting the survey
        dispatch(removeUserSurvey(selectedSurvey.id));
        navigate('/'+role+'/questionnaire/passer', { state: { selectedSurvey } });
    };

     const handleCommencer = async () => {
        try {
            const response = await api.post(`/surveys/join/${selectedSurvey.id}`);
            const {user_survey, survey_data} = response.data;
           /*  console.log('Survey joined successfully:', surveyData); */
              // Ensure user_survey contains the expected fields
            if(user_survey){ 
                if (
                    typeof user_survey.user_id === 'number' &&
                    typeof user_survey.survey_id === 'number' &&
                    (typeof user_survey.current_question_id === 'number' || user_survey.current_question_id === null)
                ) {
                    // Dispatch the action to update the Redux store with the user survey
                    dispatch(addOrUpdateUserSurvey(user_survey));
                    console.log("current_question_id",user_survey.current_question_id);
                    dispatch(showSnackbar('Survey started', 'success'))
                    await getQuestion(user_survey.current_question_id)
                    navigate('/'+role+'/questionnaire/passer', { state: { selectedSurvey: selectedSurvey } });
                 
                } else {
                    console.error('Invalid user_survey data:', user_survey);
                    // Handle invalid user_survey data (e.g., show an error message)
                    dispatch(showSnackbar('Invalid user_survey data', 'error'))
                }
            }
            // You can navigate to the next step and pass the survey data if needed
        } catch (error) {
            console.error('Error joining survey:', error.response?.data || error.message);

            dispatch(showSnackbar('Error joining survey:', error.response?.data || error.message))

            // Handle error (e.g., show a notification to the user)
        }
    };

    const getQuestion = async (current_question_id)=>{
        setLoadingQuestion(true)
        const responseCurrentQuestion = await api.get(`/surveys/question/${current_question_id}`);
        const { id, text, choices,global_order,subsection_order, section_id,section, subsection, subsection_id } = responseCurrentQuestion.data; 
        console.log(responseCurrentQuestion.data)
        setLoadingQuestion(false)

        setQuestion({ id, text,choices, global_order, subsection_order, section_id, subsection_id, section, subsection })
    }
    const getQuestionByOrder = async (order_global)=>{
        setLoadingQuestion(true)
        const responseCurrentQuestion = await api.get(`/surveys/question/order/${order_global}`);
        const { id, text, answer, choices,global_order,subsection_order, section_id,section, subsection, subsection_id } = responseCurrentQuestion.data; 
        console.log(responseCurrentQuestion.data)
        if(answer){
            setActiveChoice(answer)
        }else{
            setActiveChoice(null)
        }
        console.log(existingSurvey)
        const newSurvey = {...existingSurvey, current_question_id: id}
        dispatch(addOrUpdateUserSurvey(newSurvey))
        setLoadingQuestion(false)
        setQuestion({ id, text,choices, global_order, subsection_order, section_id, subsection_id, section, subsection })
    }

    const answerrQuestion = async ()=>{
        console.log("question", question.id)
        console.log("activeChoice",activeChoice)
        console.log("existingSurvey",existingSurvey)
        const responseCurrentQuestion = await api.get(`/surveys/question/answer/${question.id}?choice_id=${activeChoice.id}&survey_id=${existingSurvey.survey_id}&user_survey_id&=${existingSurvey.id}`);
        const { id, text, choices,global_order,subsection_order, section_id,section, subsection, subsection_id } = responseCurrentQuestion.data; 
        console.log(existingSurvey)
        const newSurvey = {...existingSurvey, current_question_id: id}
        dispatch(addOrUpdateUserSurvey(newSurvey))
        setActiveChoice(null)
        setQuestion({ id, text,choices, global_order, subsection_order, section_id, subsection_id,section, subsection })
    }

    const handleNavigateToList = ()=>{
        setQuestion(null)
        navigate('/'+role+'/questionnaire/list');
        
    }
    const handleAnswer = (item) => {
        console.log(item);
        setActiveChoice(item)
        setAnswer(item); // This triggers the effect below
    };
    
    useEffect(() => {
        if (answer) {
            const answerQuestion = async () => {
                console.log("AnswerQuestion => ", question.id);
                console.log("question", question.id);
                console.log("activeChoice", answer);
                console.log("existingSurvey", existingSurvey.id);
                
                try {
                    const responseCurrentQuestion = await api.get(`/surveys/question/answer/${question.id}?choice_id=${answer.id}&survey_id=${existingSurvey.survey_id}&user_survey_id=${existingSurvey.id}`);
                    const { id, text, isFinished, choices, global_order, subsection_order, section_id, section, subsection, subsection_id } = responseCurrentQuestion.data;
    
                    console.log(existingSurvey);
                    if(!isFinished){
                        const newSurvey = { ...existingSurvey, current_question_id: id };
                        dispatch(addOrUpdateUserSurvey(newSurvey));
                        setActiveChoice(null);
                        setAnswer(null);
                        setQuestion({ id, text, choices, global_order, subsection_order, section_id, subsection_id, section, subsection });
                    }else{
                        setIsFinished(true);
                        setQuestion(null);
                        setAnswer(null);
                        setActiveChoice(null);
                    }
                    
                } catch (error) {
                    dispatch(showSnackbar("Erreur d'enregistrement de votre réponse ", error.response?.data || error.message))
                    console.error("Error answering question:", error);
                }
            };
    
            answerQuestion();
        }
       
        if(question && question.global_order === 1){
            setPrevious(false)
        }else{
            setPrevious(true)

        }
    }, [answer, question, dispatch]);

    const handlePreviousQuestion = async () =>{
        if(question.global_order > 1){
            await getQuestionByOrder(question.global_order - 1)
        }
    }

    const showResults = async () =>{
        try {
            const response = await api.get(`/surveys/check_current_user_survey/${selectedSurvey.id}`, {
                                headers: {
                                    'Content-Type': 'application/json',
                                }
                            });
    
            /*    const result = await response.json(); */
            console.log(response.data)
            if (response.data.exists) {
                console.log(response.data.users_surveys)
                 // Add the survey to Redux state, avoiding redundancy
                dispatch(addOrUpdateUserSurvey(response.data.users_surveys));
             
                navigate('/'+role+'/diagnostics/resultat/'+response.data.users_surveys.id);
            } else {
              dispatch(showSnackbar("Essayez d'abord de commencer le diagnostic afin de pouvoir prévisualiser vos résultats"))
            }
        } catch (error) {
            console.error('Error checking user survey:', error);
        }
    }

    //const surveyExists = currentUserSurveys.some(survey => survey.survey_id === selectedSurvey?.id);
    const existingSurvey = currentUserSurveys.find(survey => survey.survey_id === selectedSurvey.id);


    return (

        <Box sx={{ display: 'flex', flexDirection: 'column', height:"95% "}}>
            {
                existingSurvey  ? 
                
                    isFinished ?
                        // Card content when the survey is FInished
                        <SurveyFinished 
                            selectedSurvey={selectedSurvey}
                            handleResult={showResults}
                            handleNavigateToList={handleNavigateToList}
                        />
                    :
                    (
                        // Card content when the survey exists
                    
                        <Box sx={{ display: 'flex', flexDirection: 'column', height:"100% "}}>
                            <Card sx={{ position:"relative", p: 2,  mb: 2 }}>
                                <Box sx={{ 
                                    justifyContent: "space-between",
                                    display: "flex",
                                    flexWrap:"wrap",
                                    flexDirection: "row", gap: 1, 
                                    alignItems: "center"
                                }}>
                                    <Box sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        flexWrap: "wrap",
                                        gap: "5px",
                                        justifyContent: "start",
                                    }}>
                                        <Box onClick={handleNavigateToList} sx={{cursor:"pointer", padding: "5px", borderRadius: "8px", background: Colors.DARK_GREEN }}>
                                            <ChevronLeftOutlinedIcon fontSize="small" sx={{ color: "#fff" }} />
                                        </Box>
                                        <Box>
                                            <Typography sx={{ mt: 0, fontSize: "14px", lineHeight: "1.2", fontWeight: 600, color: Colors.GREEN }} variant="body1">
                                                Questionnaire
                                            </Typography>
                                            <Typography sx={{ mt: 0, lineHeight: "1.2", fontSize: "16px", fontWeight: 700, color: Colors.TEXT_BODY }} variant="body1">
                                                {selectedSurvey.title}
                                            </Typography>

                                        </Box>
                                    </Box>
                                    <Box sx={{display:"flex", flexWrap:"wrap", gap:"8px", alignItems:"center"}}>
                                        <Box sx={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                                            <Typography variant="body1" sx={{color: Colors.MEDIUM_GREEN , fontSize:"14px"}} fontWeight={700}>
                                                    {question && question.section ? ` Section ${question.section.global_order} de ${selectedSurvey.sections} ` : " "}
                                            </Typography>
                                            <Typography variant="h5" color={Colors.GREEN} fontWeight={700}>
                                                {question && question.section ? ` ${question.section.title}` : ` `}
                                            </Typography>
                                        
                                                
                                        </Box>
                                        <Box sx={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                                                <Typography variant="body1"  style={{ fontSize:"14px",color: Colors.MEDIUM_GREEN }} fontWeight={700}>
                                                        {question && question.subsection ? ` Rubrique ${question.subsection.global_order} de ${selectedSurvey.subsections} ` : " "}
                                                </Typography>
                                                <Typography variant="h5"  color={Colors.GREEN} fontWeight={700}>
                                                    {question && question.subsection ? ` ${question.subsection.title}` : " "}                  
                                                </Typography>
                                        </Box>
                                        <Box sx={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                                                <Typography variant="body1"  style={{ fontSize:"14px",color: Colors.MEDIUM_GREEN }} fontWeight={700}>
                                                        {` Question`}
                                                </Typography>
                                                <Typography variant="h5"  color={Colors.GREEN} fontWeight={700}>
                                                {question ? ` ${question.subsection_order} / ${question.subsection.total}` : " "}                
                                                </Typography>
                                        </Box>
                                    </Box>
                                   
                                </Box>
                                {question && question.section ? 
                                    <Typography sx={{mt:2}} variant="body2" color={Colors.TITLE_2}>
                                         {question.section.description?.split('.').map((line, index) => (
                                             (line && line !== "") ? <p key={index} style={{ marginTop: "5px",marginBottom: "5px"}}>
                                                    {line}.
                                            </p> : null
                                        ))}</Typography>
                                
                                :
                                    null
                                    
                                }
                                
                                {/* Progress Bar */}
                                <Box sx={{ position:"absolute", left:0, right:0, bottom:0, width: '100%', mt: 2 }}>
                                    {question && question.subsection ? 
                                        <LinearProgress
                                        variant="determinate"
                                        value={ (question && question.subsection ? question.subsection_order : 1 )*100 / question.subsection.total } // progress should be a number between 0 and 100
                                        sx={{
                                            height: 10,
                                            borderRadius: 5,
                                            backgroundColor: Colors.DARK_GREEN, // background color of the progress bar
                                            '& .MuiLinearProgress-bar': {
                                                backgroundColor: Colors.GREEN, // color of the progress
                                            },
                                        }}
                                        />
                                        :
                                        null
                                    }
                                
                                
                                </Box>
                                {/* Progress Bar */}
                            {/*    <Box sx={{ position:"absolute", left:0, right:0, top:0, width: '100%'}}>
                                    
                                    <LinearProgress
                                        variant="determinate"
                                        value={ question.section.global_order *10 } // progress should be a number between 0 and 100
                                        sx={{
                                            height: 10,
                                            borderRadius: 5,
                                            backgroundColor: Colors.LIGHT_GRAY, // background color of the progress bar
                                            '& .MuiLinearProgress-bar': {
                                                backgroundColor: Colors.LIGHT_BLUE, // color of the progress
                                            },
                                        }}
                                    />
                                </Box> */}
                            </Card>
                            <Card sx={{ flex: 1, p: 2, flexGrow:1 }}>

                                {
                                    question ? 
                                    (
                                    <CardContent>
                                    
                                        <Typography variant="h4" color={Colors.TEXT_BODY} fontWeight={500}>
                                            {question.text}
                                        </Typography>
                                        {
                                            question.choices.map((item)=>(
                                                <Box 
                                                    key={item.id}
                                                    onClick={()=>handleAnswer(item)} 
                                                    sx={{
                                                        boxShadow:2, cursor:"pointer", 
                                                        py:"10px", px:3, mt:2, borderRadius:"8px", 
                                                        borderStyle:"solid",
                                                        borderWidth: "1px", 
                                                        display:"flex",justifyContent:"space-between",alignItems:"center",
                                                        borderColor: activeChoice?.id === item.id ? Colors.GREEN : "transparent" 
                                                    }}
                                                >
                                                    <Typography 
                                                        variant="h6" 
                                                        color={ activeChoice ? 
                                                                    (activeChoice.id === item.id ? Colors.TEXT_BODY : Colors.LIGHT_GREY)
                                                                    : Colors.TEXT_BODY
                                                            } 
                                                        sx={{fontSize:"17px"}}
                                                        fontWeight={500}
                                                    >
                                                    {item.text}
                                                    </Typography>
                                                    {
                                                        activeChoice &&  activeChoice.id === item.id ?  <CheckCircleOutlineIcon sx={{color: Colors.GREEN}}/> : null
                                                    }
                                                
                                                </Box>
                                                
                                            ))
                                        }
                                        <Box
                                            sx={{
                                                mt:3,
                                                display:"flex",
                                                justifyContent:"space-between",
                                                alignItems:"center"
                                            }}
                                        >
                                            <Box>
                                                <FormButton sx={{mr:2, display: "flex", justifyContent:"start", alignItems:"center" }} disabled={!previous} onClick={handlePreviousQuestion}>
                                                    <ChevronLeftOutlined sx={{mr:1, color: Colors.GREEN}}/>
                                                    Question précédente</FormButton>
                                            {/*  <FormButton disabled={!activeChoice} onClick={ () => answerQuestion()}>Suivant</FormButton> */}
                                            </Box>
                                           
                                        
                                        </Box>
                                    </CardContent>
                                    ): 

                                        loadingQuestion ? 

                                        <CardContent sx={{height:"90%", display:"flex", flexDirection:"column", p: 3}}>
                                            <Typography variant="h6" color={Colors.TITLE_1} fontWeight={600}>
                                               Question en cours de chargement
                                            </Typography>
                                        </CardContent> 
                                        :
                                        <CardContent sx={{height:"90%", display:"flex", flexDirection:"column", p: 3}}>
                                        <Typography variant="h6" color={Colors.TITLE_1} fontWeight={600}>
                                            Vous avez déjà commencé ce questionnaire{/*  vous êtes arrivés {existingSurvey.current_question_id} */}
                                        </Typography>
                                        <Box sx={{flexGrow:1}}>
                                            <Typography sx={{ mt: 2, fontSize: "14px" }} variant="body1" color={Colors.TEXT_BODY} fontWeight={400}>
                                                Vous avez déjà commencé ce questionnaire. Cliquez sur &quot;Continuer&quot; pour poursuivre là où vous vous étiez arrêté ou sur &quot;Commencer à nouveau&quot; si vous souhaitez recommencer dès le début.
                                            </Typography>
                                        </Box>
                                    
                                        <Box sx={{ mt: 3, display: 'flex', gap: 2, justifyContent: "space-between", flexWrap: "wrap" }}>
                                            <FormButtonCancel onClick={handleRestart} sx={{border: "solid 1px "+Colors.GREEN}}>Commencer à nouveau</FormButtonCancel>
                                            <FormButton onClick={handleContinue}>Continuer</FormButton>
                                        </Box>
                                    </CardContent> 
                                }
                                
                            </Card>
                        </Box>
                    ) 
                : (
                    // Card content when the survey does not exist
                    <Box sx={{ display: 'flex', flexDirection: 'column', height:"100% "}}>
                        <Box sx={{ width: "100%", display: "flex", flexDirection: "row", gap: 2, alignItems: "stretch", mb: 2 }}>
                            <Card sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: "8px 14px",
                            }}>
                                <Box onClick={handleNavigateToList} sx={{ cursor:"pointer", padding: "5px", borderRadius: "8px", background: Colors.VERY_DARK_BLUE_2 }}>
                                    <ChevronLeftOutlinedIcon fontSize="small" sx={{ color: "#fff" }} />
                                </Box>
                            </Card>
            
                            <Card sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', p: 2 }}>
                                <img src="/teams_add.png" alt="Icon" style={{ width: 24, height: 24, marginRight: 8 }} />
                                <Typography variant="h5" color={Colors.DARK_BLUE} fontWeight={700}>
                                    {selectedSurvey ? `Questionnaire ${selectedSurvey.title}` : "Aucun questionnaire sélectionné"}
                                </Typography>
                            </Card>
                        </Box>
                        <Card sx={{ flex: 1, p: 2,flexGrow:1 }}>
                            <CardContent>
                                <Typography variant="h6" color={Colors.TITLE_1} fontWeight={600}>
                                    Introduction à la Cartographie Expert Comptable
                                </Typography>
                                <Typography sx={{ mt: 2, fontSize: "14px" }} variant="body1" color={Colors.TEXT_BODY} fontWeight={400}>
                                    Ce questionnaire approfondi est conçu pour dresser un portrait détaillé et complet de votre cabinet d&apos;expertise comptable à travers 10 dimensions clés.
                                </Typography>
                                <Typography sx={{ mt: 1, fontSize: "14px" }} variant="body1" color={Colors.TEXT_BODY} fontWeight={400}>
                                    Il comprend un total de 146 questions soigneusement élaborées pour capturer avec précision tous les aspects de votre pratique.
                                </Typography>
                                <Typography sx={{ mt: 3, fontSize: "14px" }} variant="body1" color={Colors.TEXT_BODY} fontWeight={400}>
                                    <span style={{ fontWeight: 700, color: Colors.TITLE_1 }}>Objectif : </span>
                                    Fournir une analyse exhaustive de votre cabinet, identifier vos forces et vos axes d&apos;amélioration, et vous offrir des recommandations hautement personnalisées pour optimiser votre performance.
                                </Typography>
                                <Typography sx={{ mt: 2, fontSize: "14px" }} variant="body1" color={Colors.TEXT_BODY} fontWeight={400}>
                                    <span style={{ fontWeight: 700, color: Colors.TITLE_1 }}>Temps estimé : </span>
                                    20 à 25 minutes
                                </Typography>
                                <Typography sx={{ mt: 2, fontSize: "14px" }} variant="body1" color={Colors.TEXT_BODY} fontWeight={400}>
                                    <span style={{ fontWeight: 700, color: Colors.TITLE_1 }}>Conseil : </span>
                                    Pour des résultats optimaux, nous vous recommandons de répondre en plusieurs sessions si nécessaire. Vos réponses sont automatiquement sauvegardées, vous permettant de reprendre là où vous vous êtes arrêté.
                                </Typography>
                                <Typography sx={{ mt: 3, fontSize: "14px" }} variant="body1" color={Colors.TEXT_BODY} fontWeight={400}>
                                    Répondez le plus honnêtement possible. Il n&apos;y a pas de "bonne" ou de "mauvaise" réponse - seulement votre réalité actuelle.
                                </Typography>
                                <Typography sx={{ mt: 1, fontSize: "14px" }} variant="body1" color={Colors.TEXT_BODY} fontStyle={"italic"} fontWeight={400}>
                                    Prêt à entamer cette exploration approfondie de votre cabinet ?
                                    Cliquez sur "Commencer" pour débuter votre cartographie !
                                </Typography>
                                <div style={{ width: "100%", display: "flex", justifyContent: "end" }}>
                                    <FormButton onClick={handleCommencer}  sx={{ mt: 3 }} >Commencer</FormButton>
                                </div>
                            </CardContent>
                        </Card>
                    </Box>
                )
            }
        </Box>
    );
};

export default QuestionnaireReponse;

