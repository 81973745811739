import { FETCH_DIAGNOSIS_FAILURE, FETCH_DIAGNOSIS_REQUEST, FETCH_DIAGNOSIS_SUCCESS, SET_DIAGNOSIS_CURRENT_PAGE, SET_DIAGNOSIS_FILTERS, SET_DIAGNOSIS_PER_PAGE, SET_DIAGNOSIS_SORT_BY, SET_DIAGNOSIS_SORT_ORDER } from "../actions/diagnosisActions";

  
  const initialState = {
    diagnosis: [],
    totalPages: 1,
    currentPage: 1,
    filters: {},
    sortBy: 'id',
    sortOrder: 'desc',
    perPage: 10,
    loading: false,
    error: null,
  };
  
  const diagnosisReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_DIAGNOSIS_REQUEST:
        return { ...state, loading: true, error: null };
      case FETCH_DIAGNOSIS_SUCCESS:
        return {
          ...state,
          diagnosis: action.payload.diagnosis,
          totalPages: action.payload.total_pages,
          currentPage: action.payload.current_page,
          loading: false,
        };
      case FETCH_DIAGNOSIS_FAILURE:
        return { ...state, loading: false, error: action.payload };
      case SET_DIAGNOSIS_FILTERS:
        return { ...state, filters: action.payload };
      case SET_DIAGNOSIS_SORT_BY:
        return { ...state, sortBy: action.payload };
      case SET_DIAGNOSIS_SORT_ORDER:
        return { ...state, sortOrder: action.payload };
      case SET_DIAGNOSIS_PER_PAGE:
        return { ...state, perPage: action.payload };
      case SET_DIAGNOSIS_CURRENT_PAGE:
        return { ...state, currentPage: action.payload };
      default:
        return state;
    }
  };
  
  export default diagnosisReducer;
  