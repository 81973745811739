import { 
  FETCH_ALL_TEAMS_FAILURE,
    FETCH_ALL_TEAMS_REQUEST,
    FETCH_ALL_TEAMS_SUCCESS,
    FETCH_TEAMS_FAILURE, 
    FETCH_TEAMS_REQUEST, 
    FETCH_TEAMS_SUCCESS, 
    SET_CURRENT_PAGE, 
    SET_FILTERS, 
    SET_PER_PAGE, 
    SET_SORT_BY, 
    SET_SORT_ORDER 
  } from '../actions/teamActions';
  
  const initialState = {
    teams: [],
    allTeams: [], 
    totalPages: 1,
    currentPage: 1,
    filters: {},
    sortBy: 'id',
    sortOrder: 'desc',
    perPage: 10,
    loading: false,
    error: null,
  };
  
  const teamReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_TEAMS_REQUEST:
        return { ...state, loading: true, error: null };
     
      case FETCH_TEAMS_SUCCESS:
        return {
          ...state,
          teams: action.payload.teams,
          totalPages: action.payload.total_pages,
          currentPage: action.payload.current_page,
          loading: false,
        };
      case FETCH_TEAMS_FAILURE:
        return { ...state, loading: false, error: action.payload };
      
      case FETCH_ALL_TEAMS_REQUEST:
        return { ...state, loading: true, error: null };
      
      case FETCH_ALL_TEAMS_SUCCESS:
        return {
          ...state,
          allTeams: action.payload, // Update allTeams
          loading: false,
        };
      case FETCH_ALL_TEAMS_FAILURE:
        return { ...state, loading: false, error: action.payload };
      case SET_FILTERS:
        return { ...state, filters: action.payload };
      case SET_SORT_BY:
        return { ...state, sortBy: action.payload };
      case SET_SORT_ORDER:
        return { ...state, sortOrder: action.payload };
      case SET_PER_PAGE:
        return { ...state, perPage: action.payload };
      case SET_CURRENT_PAGE:
        return { ...state, currentPage: action.payload };
      default:
        return state;
    }
  };
  
  export default teamReducer;
  