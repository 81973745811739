/* eslint-disable react/prop-types */
// src/components/CounterWidget.js
import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import Colors from 'src/styles/Colors';

const CounterWidget = ({ title, value, additionalInfo, additionalValue, iconUrl, onClick, isActive }) => {
  return (
    <Card
      onClick={onClick}
      sx={{
        borderRadius: 4,
        height: '100%',
        boxShadow: isActive ? '0px 4px 10px rgba(0, 0, 0, 0.5)' : 2,
        opacity: isActive ? 1 : 0.7,
        position: 'relative',
        transition: 'box-shadow 0.3s ease, opacity 0.3s ease',
        cursor: 'pointer',
      }}
    >
      <CardContent>
        <Typography
          color={Colors.TITLE_3}
          variant="h6"
          component="div"
          gutterBottom
          style={{ maxWidth: '70%', lineHeight: '1.3' }}
        >
          {title}
        </Typography>
        <Typography
          variant="h4"
          component="div"
          color="secondary"
          sx={{ mt: 2, lineHeight: 1, fontWeight: 800, color: '#334A52' }}
        >
          {value}
        </Typography>
        <Typography
          sx={{ color: Colors.TITLE_2, fontSize: '0.6rem' }}
          variant="caption"
          component="div"
        >
          <span style={{ color: Colors.GREEN }}>{additionalValue}</span> {additionalInfo}
        </Typography>
      </CardContent>
      {iconUrl && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: '5px',
            width: '40px',
            height: '40px',
            backgroundImage: `url(${iconUrl})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            zIndex: 1,
          }}
        />
      )}
    </Card>
  );
};

export default CounterWidget;
