/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography, CircularProgress, IconButton } from '@mui/material';
import { FormButton, FormButtonCancel } from 'src/styles/CustomStyles';
import Colors from 'src/styles/Colors';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const DeleteTeamModal = ({ open, onClose, onSubmit, teamData, loading }) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>
      <Box sx={{ position: 'relative', py: 1, display: 'flex', alignItems: 'center' }}>
        <img src="/teams_add.png" alt="Icon" style={{ width: 24, height: 24, marginRight: 8 }} />
        <Typography variant="h6" color={Colors.DARK_GREEN} fontWeight={700}>
          Supprimer le cabinet comptable
        </Typography>
        <IconButton onClick={onClose} sx={{ position: 'absolute', top: 8, right: 8 }}>
          <CloseOutlinedIcon />
        </IconButton>
      </Box>
    </DialogTitle>
    <DialogContent>
      <Typography>
        Êtes-vous sûr de vouloir supprimer le cabinet comptable {teamData.name} ?
      </Typography>
    </DialogContent>
    <DialogActions
      sx={{ px: 2, py: 1 }}
      style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
    >
      <FormButtonCancel variant="outlined" onClick={onClose}>
        Annuler
      </FormButtonCancel>
      <FormButton onClick={onSubmit} disabled={loading}>
        {loading ? <CircularProgress size={24} thickness={5} sx={{color: Colors.GREEN}} />  : null } Supprimer
      </FormButton>
    </DialogActions>
  </Dialog>
);

export default DeleteTeamModal;
