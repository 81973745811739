import { Box, Card, CardContent, Chip, CircularProgress, Grid, IconButton, InputLabel, LinearProgress, Menu, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from 'react';
import Colors from "src/styles/Colors";

import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import { setPerPage } from 'src/store/actions/diagnosisActions';
import { setCurrentPage } from 'src/store/actions/diagnosisActions';
import { fetchDiagnosis } from 'src/store/actions/diagnosisActions';
import { useDispatch, useSelector } from 'react-redux';
import { CustomTablePagination } from 'src/styles/CustomStyles';

import { useNavigate } from 'react-router-dom';
import useAxios from 'src/services/apiInterceptor';
import { FilterButton } from 'src/styles/CustomStyles';
import { FilterListFormControl } from 'src/styles/CustomStyles';
import { FiltersTextField } from 'src/styles/CustomStyles';
import * as XLSX from 'xlsx';
import { setFilters } from 'src/store/actions/diagnosisActions';
import { setSortBy } from 'src/store/actions/diagnosisActions';
import { setSortOrder } from 'src/store/actions/diagnosisActions';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';

import { fetchAllTeams } from 'src/store/actions/teamActions';
import { BarChartRounded, StopCircleOutlined } from '@mui/icons-material';
import DiagnosisStatsBar from './components/DiagnosisPersonal';
import { fetchAllSurveys } from 'src/store/actions/surveysActions';
import ResultsModal from './Modal/ResultsModal';




const exportToExcel = (data) => {
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, 'Liste des Diagnostics');
    XLSX.writeFile(wb, 'liste_des_diagnostics.xlsx');
};
const ResultatsList = () => {
  
    const dispatch = useDispatch();
    const {
      diagnosis,
      totalPages,
      currentPage,
      filters,
      sortBy,
      sortOrder,
      perPage,
      loading,
      error
    } = useSelector((state) => state.diagnosis);
  
    const [page, setPage] = useState(currentPage - 1);
    const [rowsPerPage, setRowsPerPage] = useState(perPage);
    // eslint-disable-next-line no-unused-vars

    const navigate = useNavigate();
    const api = useAxios();
    
    const {user}= useSelector((state)=>state.auth)
    
    useEffect(() => {
      dispatch(fetchDiagnosis(page + 1));
    }, [dispatch, filters, sortBy, sortOrder, rowsPerPage, page]);
  
    useEffect(() => {
        console.log("user from use Effect")
        console.log(user)
      }, []);
    
    useEffect(() => {
      if (page >= totalPages && totalPages > 0) {
        setPage(totalPages - 1);
      }
    }, [totalPages, page]);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
      dispatch(setCurrentPage(newPage + 1));
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      dispatch(setPerPage(parseInt(event.target.value, 10)));
      setPage(0);
    };
  
    
  

    const handleClearFilters = () => {
        dispatch(setFilters({ 
            team_name: '',
            survey_name: '',
            collaborator: '',
            status: '' }));
        dispatch(setSortBy('created_at'));
        dispatch(setSortOrder('desc'));
    };
  


    const [resultsModal, setResultsModal] = useState(false);
    const [resultsLoading, setResultsLoading] = useState(false);
    const [activeDiagnosis, setActiveDiagnosis] =useState(null);

    const handleResultsModalOpen = (diagnosis) => {
        setActiveDiagnosis(diagnosis);
        setResultsModal(true);
    };

    const handleResultsModalClose = () => {
        setActiveDiagnosis(null);

        setResultsModal(false);
        
    };

    const handleResultsModalSubmit = () => {
        // Handle modal submission logic
        handleResultsModalClose();
        handleClearFilters();
        dispatch(setSortBy('created_at'));
        dispatch(setSortOrder('desc'));
    };

    const { allTeams = [], loading: teamsLoading, error: teamsError  } = useSelector(state => state.teams);

    useEffect(() => {
      dispatch(fetchAllTeams());
    }, [dispatch]);

    const { allSurveys = [], loading: surveysLoading, error: surveysError  } = useSelector(state => state.surveys);

    useEffect(() => {
        dispatch(fetchAllSurveys());
      }, [dispatch]);

    return (
      <Box sx={{ mt: 0 }}>
        <Box mb={2} variant="section">
            <Typography variant="h6" gutterBottom color={Colors.TITLE_1}>
                Vue d&apos;ensemble des diagnostics en cours
            </Typography>
            <DiagnosisStatsBar />
        </Box>
        <Box display="flex" justifyContent="end" flexWrap="wrap" gap={2}>
                
                {/* Last Name Filter */}
                <FiltersTextField
                    label="Expert comptable"
                    variant="outlined"
                    size="small"
                    value={filters.collaborator || ''}
                    onChange={(e) => dispatch(setFilters({ ...filters, collaborator: e.target.value }))}
                />
                {/* Team Filter */}
                {/* <FilterListFormControl size="small">
                    <InputLabel>Cabinet comptable</InputLabel>
                    <Select
                    label="Cabinet comptable"
                    name="team_name"
                    value={filters.team_name || ''}
                    onChange={(e) => dispatch(setFilters({ ...filters, team_name: e.target.value }))}
                    >
                    {allTeams.map((team) => (
                        <MenuItem key={team.id} value={team.name}>
                            {team.name}
                        </MenuItem>
                    ))}
                    </Select>
                </FilterListFormControl> */}
                 {/* Surveys Filter */}
                 <FilterListFormControl size="small">
                    <InputLabel>Enquête</InputLabel>
                    <Select
                    label="Enquête"
                    name="survey_name"
                    value={filters.survey_name || ''}
                    onChange={(e) => dispatch(setFilters({ ...filters, survey_name: e.target.value }))}
                    >
                    {allSurveys.map((survey) => (
                        <MenuItem key={survey.id} value={survey.title}>
                        {survey.title}
                        </MenuItem>
                    ))}
                    </Select>
                </FilterListFormControl>
                 {/* Team Filter */}
                 <FilterListFormControl size="small">
                    <InputLabel>Etat</InputLabel>
                    <Select
                    label="Etat"
                    name="status"
                    value={filters.status || ''}
                    onChange={(e) => dispatch(setFilters({ ...filters, status: e.target.value }))}
                    >
                        <MenuItem key={'in_progress'} value={'in_progress'}>
                            En progression
                        </MenuItem>
                        <MenuItem key={'finished'} value={'finished'}>
                            Terminé
                        </MenuItem>
                    </Select>
                </FilterListFormControl>
                {/* Sorting Options */}
                <FilterListFormControl size="small">
                    <InputLabel id="tri-label">Tri Par</InputLabel>
                    <Select
                    labelId="tri-label"
                    id="tri-value"
                    label="Tri Par"
                    value={sortBy}
                    onChange={(e) => dispatch(setSortBy(e.target.value))}
                    >
                        <MenuItem value="id">ID</MenuItem>
                        <MenuItem value="survey_name">Enquête</MenuItem>
                        <MenuItem value="user_cabinet">Cabinet</MenuItem>
                        <MenuItem value="user_first_name">Expert comptable</MenuItem>
                        <MenuItem value="created_at">Créé Le</MenuItem>
                    </Select>
                </FilterListFormControl>

                {/* Sorting Order */}
                <FilterListFormControl size="small">
                    <InputLabel  id="tri-order-label">Ordre de Tri</InputLabel>
                    <Select
                     labelId="tri-order-label"
                     id="tri-order-value"
                     label="Ordre de Tri"
                    value={sortOrder}
                    onChange={(e) => dispatch(setSortOrder(e.target.value))}
                    >
                    <MenuItem value="asc">Ascendant</MenuItem>
                    <MenuItem value="desc">Descendant</MenuItem>
                    </Select>
                </FilterListFormControl>

                <IconButton onClick={handleClearFilters} color="primary">
                    <RefreshOutlinedIcon />
                </IconButton>
                {/* Export Button */}
                <FilterButton
                    variant="contained"
                    color="primary"
                    startIcon={<FileDownloadOutlinedIcon />}
                    onClick={() => exportToExcel(diagnosis)}
                >
                    Exporter
                </FilterButton>
        </Box>
        {/* Table Container */}
        <Box sx={{ flexGrow: 1, overflowX: 'auto', mt: 1 }}>
            <Table sx={{ width: '100%', background: "#fff", borderRadius: "15px" }}>
                <TableHead>
                    <TableRow>
                        <TableCell className="center-align">ID</TableCell>
                        <TableCell className="center-align">Nom</TableCell>
                        <TableCell className="center-align">Cabinet</TableCell>
                        <TableCell className="center-align">Enquête</TableCell>
                        <TableCell className="center-align">Etat</TableCell>
                        <TableCell className="center-align">Progression</TableCell>
                        <TableCell className="center-align">Crée le</TableCell>
                        <TableCell className="center-align">Modifié le</TableCell>
                        <TableCell className="center-align">Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading ? (
                        <TableRow style={{ height: 270 }}>
                            <TableCell colSpan={9} align="center">
                                <Typography>Loading...</Typography>
                            </TableCell>
                        </TableRow>
                    ) : error ? (
                        <TableRow style={{ height: 270 }}>
                            <TableCell colSpan={9} align="center">
                                <Typography>Error: {error}</Typography>
                            </TableCell>
                        </TableRow>
                    ) : (
                        diagnosis.map((item) => (
                            <TableRow key={item.id} 
                                sx={{
                                    textAlign: 'center'
                            }}>
                                <TableCell className="center-align">{item.id}</TableCell>
                                <TableCell className="center-align">{item.user_first_name} {item.user_last_name}</TableCell>
                                <TableCell className="center-align">{item.user_role === "manager" ? "Manager" : item.user_cabinet }</TableCell>
                                <TableCell className="center-align">{item.survey_name}</TableCell>
                                <TableCell className="center-align">
                                    <Chip 
                                        sx={{ 
                                            color: "#fff",
                                            height:"24px",
                                            backgroundColor: item.status === "in_progress" ? 
                                                Colors.ORANGE :
                                                Colors.GREEN
                                        }}
                                        label={
                                            item.status === "in_progress" ? 
                                            "En progression" :
                                            "Terminé"
                                        }
                                    />
                                </TableCell>
                                <TableCell className="center-align">
                                    <Box sx={{
                                        display: 'flex',
                                        flexDirection:"column",
                                        justifyContent:"center",
                                        alignItems: 'center'
                                    }}>
                                        <Box sx={{ width: '100%', mr: 1 }}>
                                            <LinearProgress
                                                value={item.questions_choices_count > item.total_questions ? 100 : item.questions_choices_count / item.total_questions * 100}
                                                variant="determinate"
                                                sx={{
                                                    height: 10,
                                                    borderRadius: 5,
                                                    backgroundColor: Colors.LIGHT_BLUE,
                                                    '& .MuiLinearProgress-bar': {
                                                        backgroundColor: Colors.GREEN,
                                                    },
                                                }}
                                            />
                                        </Box>
                                        <Box sx={{ minWidth: 35 }}>
                                            <Typography variant="body2" color="text.secondary">{`${item.questions_choices_count >item.total_questions ? 100:  Math.round(
                                             item.questions_choices_count / item.total_questions * 100,
                                            )}%`}</Typography>
                                        </Box>
                                    </Box>
                                </TableCell>
                                <TableCell className="center-align">
                                    {new Date(item.created_at).toLocaleString('fr-FR', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit'
                                    })}
                                </TableCell>
                                <TableCell className="center-align">
                                    {new Date(item.updated_at).toLocaleString('fr-FR', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit'
                                    })}
                                </TableCell>
                                <TableCell className="center-align" sx={{ position: 'sticky', right: 0, background: '#fff' }}>
                                    <Tooltip title="Réinitialiser le diagnostic">
                                        <IconButton sx={{ color: Colors.GREEN }} onClick={() => null}>
                                            <StopCircleOutlined />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Afficher le résultat">
                                        <IconButton sx={{ color: Colors.TITLE_3 }} onClick={()=>handleResultsModalOpen(item)}>
                                            <BarChartRounded />
                                        </IconButton>
                                    </Tooltip>
                                   
                                </TableCell>
                            </TableRow>
                        ))
                    )}
                    {diagnosis.length === 0 && (
                        <TableRow style={{ height: 270 }}>
                            <TableCell colSpan={9} align="center">
                                <Typography>Aucune Donnée</Typography>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>

            {/* Pagination */}
            <CustomTablePagination
            rowsPerPageOptions={[10, 25,50,100,300]}
            component="div"
            count={totalPages * rowsPerPage}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Afficher"
            nextIconButtonProps={{
                'aria-label': 'Page suivante',
            }}
            backIconButtonProps={{
                'aria-label': 'Page précédente',
            }}
            />
        </Box>  
         {/* Assign Teams Modal */}
        <ResultsModal
            open={resultsModal}
            onClose={handleResultsModalClose}
            onSubmit={handleResultsModalSubmit}
            loading={resultsLoading}
            diagnosis={activeDiagnosis}
            isDialog={true}
        />    
         {/* Assign Teams Modal */}
       {/*   <EditSurvey
            open={assignTeamsModalOpen}
            onClose={handleAssignTeamsClose}
            onSubmit={handleAssignTeamsSubmit}
            diagnosis={assignTeamsSurvey?.id}
            diagnosis={diagnosis}
            teams={allTeams}
        />     */}
        {/* Add content for viewing and managing reports */}
      </Box>
    );
};
export default ResultatsList;