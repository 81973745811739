import React from 'react';
import { Container } from '@mui/material';

const EquipesAdmin = () => {
  return (
    <Container sx={{ mt: 2 }}>
      <h1>Rôle Admin Equipes</h1>
      <p>Manage  teams </p>
      {/* Add content for managing teams */}
    </Container>
  );
};

export default EquipesAdmin;
