import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, List, ListItem, ListItemText, Typography } from '@mui/material';
import { CloseOutlined, ListAltOutlined } from '@mui/icons-material';
import { FormButtonCancel } from 'src/styles/CustomStyles';
import Colors from 'src/styles/Colors';
import useAxios from 'src/services/apiInterceptor';
import { useDispatch } from 'react-redux';
import { showSnackbar } from 'src/store/actions/snackbarActions';

const ShowCollaboratorSurveys = ({ collaborator, onClose }) => {
    const api = useAxios();
    const dispatch = useDispatch();
    const [surveys, setSurveys] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (collaborator && collaborator.id) {
            const fetchSurveys = async () => {
                setLoading(true);
                try {
                    console.log(collaborator.id)
                    const response = await api.get(`/diagnosis/collaborators/${collaborator.id}/surveys`);
                    setSurveys(response.data);  // Assuming the response is an array of surveys
                } catch (error) {
                    dispatch(showSnackbar("Failed to fetch surveys", 'error'));
                } finally {
                    setLoading(false);
                }
            };
            fetchSurveys();

        }
       

    }, [collaborator, api, dispatch]);

    return (
        <Dialog 
            open 
            onClose={onClose}
            maxWidth="md"  // Set the max width of the dialog
            fullWidth      // Ensure the dialog takes up the full width based on maxWidth
            PaperProps={{
                sx: { width: '600px', minHeight: '400px', maxHeight: '90vh' },  // Customize the paper's width and height
            }}
        >
            <DialogTitle sx={{ position: "relative", display: "flex", alignItems: "center", gap: "5px" }}>
                <ListAltOutlined sx={{ color: Colors.MEDIUM_GREEN }} />
                Diagnostics en cours <span style={{ color: Colors.LIGHT_BLUE }}> {collaborator?.first_name} {collaborator?.last_name}</span>
                <IconButton
                    onClick={onClose}
                    sx={{ position: 'absolute', top: 8, right: 8 }}
                >
                    <CloseOutlined />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
    {loading ? (
        <Typography variant="body1" sx={{ mt: 2 }}>
            Chargement des diagnostics...
        </Typography>
    ) : surveys && surveys.length > 0 ? (
        <List>
            {surveys.map((survey) => (
                <ListItem key={survey.survey_title} divider>
                    <ListItemText
                        primary={survey.survey_title}
                        secondary={`
                                    Effectué par: ${survey.user_first_name} ${survey.user_last_name}
                                    Commencé le : ${survey.creation_time}
                                    Status: ${survey.status}
                                    Avancement: ${survey.order_global !== null && survey.survey_total!== null ? (survey.order_global / survey.survey_total)*100+" %" : 'N/A'}`}
                    />
                </ListItem>
            ))}
        </List>
    ) : (
        <Typography variant="body1" sx={{ mt: 2 }}>
            Aucun diagnostic n'a été effectué par cet Expert Comptable
        </Typography>
    )}
</DialogContent>
            <DialogActions>
                <FormButtonCancel onClick={onClose} color="secondary">Fermer</FormButtonCancel>
            </DialogActions>
        </Dialog>
    );
};

export default ShowCollaboratorSurveys;
