// SurveysCounter.js

import React from 'react';
import { Card, Box, Typography } from '@mui/material';

import Colors from 'src/styles/Colors';
import { ChevronLeftOutlined, WidthFull } from '@mui/icons-material';
import { FormButton } from 'src/styles/CustomStyles';
import { FormButtonCancel } from 'src/styles/CustomStyles';
import { useSelector } from 'react-redux';

const SurveyFinished = ({handleNavigateToList, selectedSurvey, handleResult}) => {
    const {user} = useSelector((state)=> state.auth);

    /* if (loading) return <Typography>Loading...</Typography>;
    if (error) return <Typography color="error">Error fetching survey count</Typography>; */

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height:"100% "}}>
            <Card sx={{ position:"relative", p: 2,  mb: 2 }}>
                <Box sx={{ 
                    justifyContent: "space-between",
                    display: "flex",
                    flexWrap:"wrap",
                    flexDirection: "row", gap: 1, 
                    alignItems: "center"
                }}>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        flexWrap: "wrap",
                        gap: "5px",
                        justifyContent: "start",
                    }}>
                        <Box onClick={handleNavigateToList} sx={{cursor:"pointer", padding: "5px", borderRadius: "8px", background: Colors.VERY_DARK_BLUE_2 }}>
                            <ChevronLeftOutlined fontSize="small" sx={{ color: "#fff" }} />
                        </Box>
                        <Box>
                            <Typography sx={{ mt: 0, fontSize: "14px", lineHeight: "1.2", fontWeight: 600, color: Colors.GREEN }} variant="body1">
                                Questionnaire
                            </Typography>
                            <Typography sx={{ mt: 0, lineHeight: "1.2", fontSize: "16px", fontWeight: 700, color: Colors.TEXT_BODY }} variant="body1">
                                {selectedSurvey.title}
                            </Typography>

                        </Box>
                    </Box>
                </Box>
            
            </Card>
            <Card 
                sx={{ 
                   display:"flex",
                   flexDirection:"column",
                   p: 2,
                   flexGrow:1,
                   alignItems:"center",
                   justifyContent:"space-around"
                   
                }}
            >

                {/* HEADER AND PICTURE SECTION */}

                <Box sx={{
                            maxWidth:{
                                md: "70%",
                                sm: "85%",
                                xs:"95%" 
                            }
                        }}
                >
                    <Box 
                        sx={{
                            display:"flex",
                            justifyContent:"center",
                            alignItems:"center"
                        }}
                    >
                        <img 
                            src="/survey-end.png" 
                            alt="Survey completed" 
                            style={{
                                width: "200px",
                                /* maxWidth:"85%", */
                            }}
                        />
                    </Box>
                    <Typography 
                        variant="h6"
                        sx={{
                            textAlign:"center",
                            color: Colors.GREEN
                        }}
                    >
                        Félicitations 🎉 Vous avez terminé le questionnaire {selectedSurvey?.title} avec succès
                    </Typography>
                    
                </Box>


                {/* CONTENT SECTION */}
                <Box 
                 display={"flex"}
                 flexDirection={"column"}
                    sx={{
                        maxWidth:{
                            md: "70%",
                            sm: "85%",
                            xs:"95%" 
                        },
                        justifyContent:"center"
                    }}>
                    <Typography 
                        variant="body2" 
                        sx={{
                            textAlign:"center",
                            color: Colors.TITLE_2
                        }}
                    >
                        Nous vous remercions d'avoir répondu à toutes les sections du questionnaire. Vous pouvez dés maintenant accéder à vos résultats et accéder à :
                    
                    </Typography> 
                    <ul 
                        style={{
                            color: Colors.TITLE_2,
                            fontWeight: 400,
                            fontSize: "0.875rem",
                            listStyle:"none",
                            textAlign:"center"
                        }}
                    >
                            <li>Une analyse détaillé de votre situation,</li>
                            <li>Les recommandations appropriées pour votre cas,</li>
                            <li>Les différents outils pour vous accompagner</li>
                    </ul>  
                </Box>
                 {/* Buttons SECTION */}
                <Box 
                    display={"flex"}
                    flexDirection={"column"}
                >
                    <FormButton onClick={handleResult}>Accéder à mon résultat</FormButton>
                    <FormButtonCancel 
                        sx={{
                            color: Colors.DARK_BLUE,
                            fontWeight:"bold",
                            mt:1
                        }}
                        onClick={handleNavigateToList}
                    >{user.role === "contributor" ? "Revenir à l'accueil": "Revenir à la liste des questionnaires"}</FormButtonCancel>
                </Box>   
                
            </Card>
        </Box>
    );
};

export default SurveyFinished;
