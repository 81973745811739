import { createSlice } from '@reduxjs/toolkit';
import { fetchDiagnosisResult } from '../actions/resultDiagnosisActions';

const initialState = {
    diagnosisResult: null,
    loading: false,
    error: null,
};

const diagnosisResultSlice = createSlice({
    name: 'diagnosis',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDiagnosisResult.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchDiagnosisResult.fulfilled, (state, action) => {
                state.loading = false;
                state.diagnosisResult = action.payload;
            })
            .addCase(fetchDiagnosisResult.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default diagnosisResultSlice.reducer;
