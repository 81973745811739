import React from 'react';
import Layout from '../_layout/Layout';
import { Container } from '@mui/material';

const Contributor = () => {
  return (
    <Layout role="contributor">
      <Container sx={{ mt: 2 }}>
        <h1>Contributor Dashboard</h1>
        <p>Welcome, Contributor! This is the contributor dashboard.</p>
        {/* Add contributor-specific content here */}
      </Container>
    </Layout>
  );
};

export default Contributor;
