import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import { TextField, Typography, IconButton, CircularProgress } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Colors from '../../styles/Colors';
import { setUser } from 'src/store/actions/authActions';
import { setToken } from 'src/store/actions/authActions';
import { useDispatch } from 'react-redux';
import { showSnackbar } from 'src/store/actions/snackbarActions';
import { clearUserSurveys } from 'src/store/actions/currentUserSurveysActions';

const Form = styled.form`
  width: 85%;
  padding: 2rem;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  min-height: 340px;
`;

const CompteCreation = styled.p`
  font-size: 14px;
  margin-bottom: 15px;
  margin-top: 3px;
`;

const StyledButton = styled.button`
  padding: 0.75rem;
  border: none;
  border-radius: 12px;
  background-color: ${Colors.DARK_BLUE};
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${Colors.LIGHT_BLUE};
  }
`;

const ForgotPasswordButton = styled.button`
  padding: 0.75rem;
  border: none;
  border-radius: 12px;
  background-color: transparent;
  color: ${Colors.GREEN};
  font-size: 1rem;
  cursor: pointer;
  width: 100%;
  text-align: center;
  margin-top: 1rem;
  transition: color 0.3s;

  &:hover {
    color: ${Colors.DARK_GREY};
  }
`;

const InputContainer = styled.div`
  position: relative;
  margin-bottom: 1rem;
`;

const EyeIconButton = styled(IconButton)`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
`;

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginLoading, setLoginLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        console.log("Hello Oussama")
        setLoginLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`HTTP error! Status: ${response.status}, Message: ${errorText}`);
      }

      const data = await response.json();
      if (data.access_token) {
        localStorage.setItem('accessToken', data.access_token);
        localStorage.setItem('user', JSON.stringify(data.user));

        dispatch(setUser(data.user));
        dispatch(setToken(data.access_token));

        dispatch(clearUserSurveys());
        if (data.user.role === 'admin') {
          navigate('/admin');
        } else if (data.user.role === 'manager') {
          navigate('/manager');
        } else if (data.user.role === 'contributor') {
          navigate('/contributor');
        }
      } else {
            dispatch(showSnackbar('Identifiants invalides', 'error'))
        }
        setLoginLoading(true);
        
    } catch (error) {
        console.error('Login failed:', error);
        dispatch(showSnackbar(`Erreur de connexion : ${error.message}`, 'error'))

        setLoginLoading(false);
        
    }
  };


  return (
    <Form onSubmit={handleSubmit}>
      <Typography variant="h5" style={{ fontWeight: 'bold', marginBottom: 0 }} component="h1" gutterBottom>
        Connectez-vous à votre espace
      </Typography>
      <CompteCreation>
        Toujours pas de compte ?{' '}
        <a href="/contact" style={{ color: Colors.GREEN, textDecoration: 'none' }}>
          Contactez-nous maintenant
        </a>
      </CompteCreation>
      <InputContainer>
        <TextField
          label="Adresse Email"
          variant="outlined"
          fullWidth
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          InputProps={{
            style: { borderRadius: '10px' }
          }}
        />
      </InputContainer>
      <InputContainer>
        <TextField
          label="Mot de passe"
          variant="outlined"
          fullWidth
          margin="normal"
          type={showPassword ? 'text' : 'password'}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <EyeIconButton onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </EyeIconButton>
            ),
            style: { borderRadius: '10px' }
          }}
        />
      </InputContainer>
      <StyledButton type="submit" style={{display: "flex", justifyContent:"center",alignItems:"center"}}>
      {loginLoading ? <CircularProgress size={24} style={{ color: '#fff', marginRight: "10px" }} /> : null } Connexion        
      </StyledButton>
      <ForgotPasswordButton onClick={() => navigate('/auth/forgot-password')}>
        Mot de passe oublié ?
      </ForgotPasswordButton>
    </Form>
  );
};

export default Login;
