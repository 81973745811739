import { Box, Card, CardContent, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAxios from "src/services/apiInterceptor";
import { addOrUpdateUserSurvey } from "src/store/actions/currentUserSurveysActions";
import { showSnackbar } from "src/store/actions/snackbarActions";
import { fetchSurveys } from "src/store/actions/surveysActions";
import Colors from "src/styles/Colors";


const DiagnosisGenerationLoader = () => {

    const [loadingGlobal, setLoadingGlobal] =useState(true);
    const api = useAxios();
    const navigate = useNavigate();

 const dispatch = useDispatch();
    const {
      surveys,
      totalPages,
      currentPage,
      filters,
      sortBy,
      sortOrder,
      perPage,
      loading,
      error
    } = useSelector((state) => state.surveys);
     

    useEffect(()=>{
        console.log("surverys are here")
        console.log(surveys)
        if(surveys && surveys.length > 0 ){
            const showResults = async () =>{
                try {
        
                    const response = await api.get(`/surveys/check_current_user_survey/${surveys[0].id}`, {
                                        headers: {
                                            'Content-Type': 'application/json',
                                        }
                                    });
            
                    /*    const result = await response.json(); */
                    console.log(response.data)
                    if (response.data.exists) {
                        console.log(response.data.users_surveys)
                        // Add the survey to Redux state, avoiding redundancy
                        dispatch(addOrUpdateUserSurvey(response.data.users_surveys));
                    
                        navigate('/contributor/diagnostics/resultat/'+response.data.users_surveys.id);
                    } else {
                        navigate('/contributor/questionnaire');

                    dispatch(showSnackbar("Essayez d'abord de commencer le diagnostic afin de pouvoir prévisualiser vos résultats"))
                    }
                } catch (error) {
                    console.error('Error checking user survey:', error);
                }finally{
                    setLoadingGlobal(false)
                }
            }
            showResults();
        }
    },[surveys]);

   
    useEffect(() => {
        dispatch(fetchSurveys());
      }, [dispatch]);

      return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', flexDirection: 'column' }}>
            <CircularProgress sx={{color: Colors.MEDIUM_GREEN}}/>
            <Typography variant="h6" sx={{ mt: 2, color: Colors.MEDIUM_GREEN }}>
            Chargement des données, veuillez patienter un moment ...
            </Typography>
        </Box>
        );
};

export default DiagnosisGenerationLoader;