import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { CircularProgress, Typography, Box } from '@mui/material';
import useAxios from 'src/services/apiInterceptor';

const VerifyEmail = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const api = useAxios();
  const location = useLocation();

  useEffect(() => {
    // Extract the token from the URL
    const query = new URLSearchParams(location.search);
    const token = query.get('token');

    if (!token) {
      setError('No token found in the URL.');
      setLoading(false);
      return;
    }

    // Make a request to the backend to verify the email
    console.log("verify email executed")
    api.post('/users/verify-email', { token })
      .then(response => {
        setSuccess(true);
      })
      .catch(err => {
        setError(err.response ? err.response.data.message : 'Une erreur est survenue');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [location.search]);

  return (
    <Box textAlign="center" mt={5}>
      {loading && <CircularProgress />}
      {!loading && success && (
        <Typography variant="h6" color="#fff">
          Email mis à jour avec succés
        </Typography>
      )}
      {!loading && error && (
        <Typography variant="h6" color="#fff">
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default VerifyEmail;
