import axios from 'axios';
import { API_URL } from 'src/config';

export const FETCH_DIAGNOSIS_REQUEST = 'FETCH_DIAGNOSIS_REQUEST';
export const FETCH_DIAGNOSIS_SUCCESS = 'FETCH_DIAGNOSIS_SUCCESS';
export const FETCH_DIAGNOSIS_FAILURE = 'FETCH_DIAGNOSIS_FAILURE';
export const SET_DIAGNOSIS_FILTERS = 'SET_DIAGNOSIS_FILTERS';
export const SET_DIAGNOSIS_SORT_BY = 'SET_DIAGNOSIS_SORT_BY';
export const SET_DIAGNOSIS_SORT_ORDER = 'SET_DIAGNOSIS_SORT_ORDER';
export const SET_DIAGNOSIS_PER_PAGE = 'SET_DIAGNOSIS_PER_PAGE';
export const SET_DIAGNOSIS_CURRENT_PAGE = 'SET_DIAGNOSIS_CURRENT_PAGE';

export const fetchDiagnosis = (page = 1) => async (dispatch, getState) => {
  const { filters, sortBy, sortOrder, perPage } = getState().diagnosis;
  const token = getState().auth.token;

  dispatch({ type: FETCH_DIAGNOSIS_REQUEST });
  
  try {
    const response = await axios.get(`${API_URL}/diagnosis/all/paginate`, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      params: {
        page,
        per_page: perPage,
        filters: new URLSearchParams(filters).toString(),
        sort_by: sortBy,
        sort_order: sortOrder
      }
    });
    
    dispatch({
      type: FETCH_DIAGNOSIS_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    dispatch({
      type: FETCH_DIAGNOSIS_FAILURE,
      payload: error.message
    });
  }
};

export const setFilters = (filters) => ({
  type: SET_DIAGNOSIS_FILTERS,
  payload: filters
});

export const setSortBy = (sortBy) => ({
  type: SET_DIAGNOSIS_SORT_BY,
  payload: sortBy
});

export const setSortOrder = (sortOrder) => ({
  type: SET_DIAGNOSIS_SORT_ORDER,
  payload: sortOrder
});

export const setPerPage = (perPage) => ({
  type: SET_DIAGNOSIS_PER_PAGE,
  payload: perPage
});

export const setCurrentPage = (currentPage) => ({
  type: SET_DIAGNOSIS_CURRENT_PAGE,
  payload: currentPage
});
