// reducers/currentUserSurveysReducer.js

import { SET_USER_SURVEY, REMOVE_USER_SURVEY, CLEAR_USER_SURVEYS } from '../actions/currentUserSurveysActions';

const initialState = JSON.parse(localStorage.getItem('currentUserSurveys')) || [];

const currentUserSurveysReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_SURVEY:
     // Ensure payload is an array
      const surveysToAdd = Array.isArray(action.payload) ? action.payload : [action.payload];
      
      // Merge new surveys into state, avoiding duplicates
      const updatedState = surveysToAdd.reduce((acc, survey) => {
        const index = acc.findIndex(s => s.survey_id === survey.survey_id);
        if (index !== -1) {
          acc[index] = survey; // Update existing survey
        } else {
          acc.push(survey); // Add new survey
        }
        return acc;
      }, [...state]);

      return updatedState;
    case REMOVE_USER_SURVEY:
      return state.filter(survey => survey.survey_id !== action.payload);
    case CLEAR_USER_SURVEYS:
      return [];
    default:
      return state;
  }
};

export default currentUserSurveysReducer;
